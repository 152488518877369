@import "../../common/variables";
@import "../../common/mixins";

.Form-Input {
  background: transparent;
  border: 0.7px solid rgba(#fff, .4);
  border-radius: 6px;
  padding: 8px 15px 6px;
  font-weight: 300;
  height: 46px;
  width: 100%;
  font-size: 24px;
  line-height: 29px;
  color: rgba(#fff, .9);
  outline: none;

  &--WithSelect {
    padding-right: 90px; }

  @include max-w($mobile_md) {
    font-size: 22px;
    line-height: 24px;
    height: 40px; }

  &--Dark {
    border-color: rgba($color-background, .4);
    color: $color-background;
    font-size: 18px;
    line-height: 21px; }
  &--Light {
    font-size: 18px; }

  &[type="password"] {
    letter-spacing: 2px;
    font-size: 38px;

    @include max-w($mobile_md) {
      font-size: 22px; } }

  &:disabled {
    background-color: #e9ecef; } }

.Form-Input__Label {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;

  @include max-w($mobile_md) {
    font-size: 12px;
    margin-bottom: 5px; }

  .Form-InputBox--White & {
    color: #fff; }

  .Form-InputBox--Dark & {
    color: $color-seventh; } }

.Form-Input__Select {
  position: absolute;
  right: 15px;
  top: 37px;

  @include max-w($mobile_md) {
    top: 30px; } }

.Form-Select-Simple__control {
  box-shadow: none !important; }

.Form-Input__Icon {
  position: absolute;
  cursor: pointer;
  top: 45px;
  right: 18px;

  @include max-w($mobile_md) {
    bottom: 6px; } }

.Form-TextArea {
  resize: none;
  height: 184px !important; }

