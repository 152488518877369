@import "../../../common/variables";
@import "../../../common/mixins";

.VerifyAccountBoxTop, .VerifyAccountBoxBottom {
  flex-direction: column;
  margin-bottom: 40px; }

.VerifyAccount {
  @include max-w($mobile_lg) {
    text-align: center; } }

.VerifyAccount__Title {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px; }

.VerifyAccount__Description {
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 47px;
  max-width: 300px;

  @include max-w($mobile_lg) {
    max-width: 100%;
    margin-bottom: 30px; } }

.VerifyAccount__Items {
  display: flex;
  margin: -10px;
  flex-wrap: wrap; }

.VerifyAccount__Item-Wrapper {
  padding: 10px 10px 10px;
  width: 50%;

  &--Large {
    width: 100%; }

  @include max-w(1576) {
    width: 100%; } }

.VerifyAccount__Item {
  background: #F4F4F4;
  border-radius: 16px;
  padding: 7px 28px 13px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .VerifyAccount__Item-Wrapper--Large & {
    padding: 14px 30px;

    @include max-w($desktop_sm) {
      flex-direction: row; }

    @include max-w($mobile_lg) {
      flex-direction: column;
      padding: 20px; } }

  .Button {
    min-width: 120px; }

  @include max-w($laptop_md) {
    flex-direction: row; }

  @include max-w($mobile_lg) {
    flex-direction: column;
    padding: 20px; } }

.VerifyAccount__Item-Title {
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;

  /*.large-title
    max-width: 70%*/

  .VerifyAccount__Item-Wrapper--Large & {
    margin-bottom: 10px; }

  @include max-w($laptop_md) {
    margin-bottom: 0; }

  @include max-w($mobile_lg) {
    text-align: center;
    flex-direction: column;
    margin-bottom: 20px;

    .large-title {
      max-width: 100%; } } }

.VerifyAccount__Item-Desc {
  font-size: 14px;
  line-height: 17px;
  color: $color-seventh;

  .VerifyAccount__Item-Wrapper--Large & {
    @include max-w($mobile_lg) {
      margin-bottom: 20px; } } }

.VerifyAccount__Item-Status {
  padding: 5px 7px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  border-radius: 23px;
  margin-left: 14px;
  white-space: nowrap;
  &--Verify {
    color: #4FB044;
    background: #E4F8DD; }

  &--NoVerify {
    color: $color-second;
    background: #FFE0D6; }

  @include max-w($mobile_lg) {
    margin-left: 0;
    margin-top: 15px; } }

.VerifyAccount__Item-Icon {
  display: inline-block;
  margin-left: 7px;
  position: relative; }

.VerifyAccount__Item-Icon svg {
  width: 13.25px;
  height: 13.25px;

  @include max-w($mobile_lg) {
    top: 3px; } }

.VerifyAccount__Fields {
  display: flex;
  flex-wrap: wrap;
  margin: -20px;

  @include max-w($laptop_md) {
    margin: -10px; } }

.VerifyAccount__Field-Wrapper {
  padding: 20px;
  width: 50%;

  @include max-w($laptop_md) {
    width: 100%;
    padding: 10px; } }

.VerifyAccount__Field {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;

  .Button {
    margin-left: 20px;

    @include max-w($mobile_lg) {
      margin-left: 0;
      margin-top: 20px; } }

  @include max-w($mobile_lg) {
    flex-direction: column;
    align-items: center; } }


.UploadDocumentsModal {
  @include max-w($mobile_lg) {
    text-align: center; } }

.UploadDocumentsModal__Title {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;
  text-transform: uppercase; }

.UploadDocumentsModal__Desc {
  font-weight: 300;
  font-size: 12px;
  line-height: 150%;
  margin-bottom: 47px;
  max-width: 90%;
  color: #212946;

  @include max-w($mobile_lg) {
    margin-bottom: 30px;
    max-width: 100%; } }

.UploadDocumentsModal__Content {
  display: flex;
  margin-bottom: 30px;

  @include max-w($mobile_lg) {
    flex-direction: column; } }

.UploadDocumentsModal__Info {
  width: calc(100% - 296px);

  @include max-w($mobile_lg) {
    text-align: left;
    padding-right: 0;
    margin: 0 auto 40px; } }

.UploadDocumentsModal__Item {
  position: relative;
  font-size: 12px;
  color: #777E8A;
  line-height: 150%;
  padding-left: 16px;

  &:not(:last-child) {
    margin-bottom: 10px; }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 7px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: $color-second; } }

.UploadDocumentsModal__Dropzone {
  max-width: 296px;
  width: 100%;

  @include max-w($mobile_lg) {
    margin: 0 auto;
    text-align: left; } }

.UploadDocumentsModal__Button {
  display: flex;
  justify-content: center; }

.UploadDocumentsModal__Items {
  display: flex;
  flex-wrap: wrap;

  .UploadDocumentsModal__Items__Item {
    width: 142px;
    height: 142px;
    margin: 0 20px 20px 0;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

    button {
      width: 24px;
      height: 24px;
      position: absolute;
      z-index: 0;
      right: -10px;
      top: -10px;
      border: none;
      background: red;
      color: #fff;
      border-radius: 50%;
      cursor: pointer; } } }

.UploadDocumentsModal__Error {
  padding: 1rem 1rem;
  margin-top: -1rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7; }
