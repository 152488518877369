@import "../../../common/variables";
@import "../../../common/mixins";

.DashboardAside {
    background-color: $color-background;
    padding: 50px 21px 40px;
    width: 100%;
    color: #fff;
    height: 100%;

    @include max-w($tablet) {
        background: none;
        width: 0; } }

.DashboardAside__Head {
    margin-bottom: 35px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @include max-w($tablet) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        margin-bottom: 0;
        align-items: center;
        padding: 12px 16px;
        background-color: $color-background;
        z-index: 2000;
        justify-content: space-between; } }


.DashboardAside__Head-Logo {
    margin-bottom: 35px;

    @include max-w($tablet) {
        margin-bottom: 0; } }

.DashboardAside__Edit {
    cursor: pointer;

    @include max-w($tablet) {
        display: none; } }

.DashboardAside__User {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;

    @include max-w($tablet) {
        width: auto;
        flex-direction: row; } }

.DashboardAside__Avatar {
    border-radius: 50%;
    background-color: $color-seventh;
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;

    @include max-w($tablet) {
        order: 1;
        width: 45px;
        height: 45px;
        margin-left: 16px;
        margin-bottom: 0;

        svg {
            width: 25px;
            height: 25px; } } }

.DashboardAside__Arrow {
    transition: transform .5s;

    .DashboardAside__UserInfo--Open & {
        transform: rotate(180deg); } }

.DashboardAside__UserName {
    font-size: 14px;
    line-height: 18px;
    margin-right: 6px; }

.DashboardAside__UserInfo {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer; }

.DashboardAside__UserSettings {
    position: absolute;
    display: none;
    left: 50%;
    bottom: -12px;
    transform: translate(-50%, 100%);
    background-color: #fff;
    border-radius: 6px;
    padding: 7px;
    width: 180px;

    .DashboardAside__UserInfo--Open & {
        display: block; } }

.DashboardAside__UserLink {
    color: $color-background;
    padding: 6px;
    display: block;
    text-align: center;

    &--Active {
        color: $color-primary; } }

.DashboardAside__Content {
    display: flex;
    flex-direction: column;
    align-items: center; }

.DashboardAside__Text {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    margin-bottom: 33px;
    text-align: center;
    display: none; }

.DashboardAside__Links {
    margin-bottom: 57px;

    @include max-w($tablet) {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        margin-bottom: 0;
        padding: 9px 24px;
        background-color: $color-background;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 2000; } }

.DashboardAside__Link {
    padding: 12px 30px;
    background: #222E52;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 17px;
    border-radius: 5px;

    @include max-w($tablet) {
        flex-direction: column;
        background: none;
        border-radius: 34px;

        // &--Center
        //     position: absolute
        //     left: 50%
        //     top: 50%
 }        //  // transform: translate(-50%, -50%)

    span {
        @include max-w($tablet) {
            font-size: 10px;
            line-height: 11px; } }

    img, svg {
        margin-right: 10px;
        width: 15px;
        height: 15px;

        @include max-w($tablet) {
            margin-right: 0;
            margin-bottom: 7px; } }

    &:not(:last-child) {
        margin-bottom: 20px;

        @include max-w($tablet) {
            margin-bottom: 0; } }

    &--Active {
        background-color: $color-primary;
        cursor: default;

        @include max-w($tablet) {
            padding: 12px 30px;


            span {
 } } } }                // display: none

.DashboardAside__Exchange {
    margin-bottom: 73px;

    @include max-w($tablet) {
        display: none; } }

.DashboardAside__Currencies {
    display: flex;
    justify-content: space-between;
    padding: 0 12px;

    @include max-w($tablet) {
        display: none; } }

.DashboardAside__CurrItem {
    &:not(:last-child) {
        margin-right: 5px; } }

.DashboardAside__CurrText {
    line-height: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    color: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    svg {
        margin-left: 5px;
        width: 8px;
        height: auto; } }

.DashboardAside__CurrValue {
    font-size: 12px;
    line-height: 12px;
    color: rgba(255, 255, 255, 0.9); }

.UploadAvatarModal {
    display: flex;
    align-items: center;
    flex-direction: column; }

.UploadAvatarTitle {
    font-weight: 600;
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;

    @include max-w($mobile_lg) {
        max-width: 200px;
        text-align: center;
        line-height: 150%;
        margin-bottom: 30px; } }

.UploadAvatarImage {
    border-radius: 50%;
    width: 210px;
    height: 210px;
    margin-bottom: 31px;
    background-color: $color-seventh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:hover {
        .UploadAvatarImageCrop {
            display: block; }

        &::after {
            opacity: 1; } }

    &::after {
        content: '';
        position: absolute;
        background-color: rgba(#000, .7);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        transition: opacity .5s; }

    @include max-w($mobile_lg) {
        width: 200px;
        height: 200px;
        margin-bottom: 30px; } }

.Modal--Avatar {
    top: calc(100vh / 2 - 279px);
    max-width: 450px;
    border-radius: 21px;
    padding: 30px;

    &--Large {
      max-width: 666px; }

    .Button {
      min-width: 120px;
      padding: 10px 15px 11px;
      font-size: 10px;
      line-height: 12px; } }

.Modal--AvatarCrop {
    top: calc(100vh / 2 - 262px); }

.Modal--AvatarCrop .ReactCrop {
    max-height: 400px;
    background: #dfdfdf; }

.UploadAvatarImageCrop {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: #fff;
    font-size: 12px;
    line-height: 14px;

    img {
        display: block;
        width: 30px;
        height: 30px;
        margin: 0 auto 10px; } }

