@import "../../common/variables";
@import "../../common/mixins";

.Footer {
  padding: 32px 0;
  background-color: $color-background;
  color: $color-text-base;

  &--White {
    background-color: $color-background-second;
    color: $color-text-second;
    padding: 64px 0 32px; }

  @include max-w($mobile_md) {
   padding-bottom: 20px; } }

.Footer__Top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;

  @include max-w($mobile_md) {
    margin-bottom: 35px; } }

.Footer__Socials {
  display: flex;
  align-items: center;

  .Social {
    &:not(:first-child) {
      margin-left: 18px; } } }

.Footer__Center {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  @include max-w($tablet) {
    flex-wrap: wrap; }

  @include max-w($mobile_md) {
    flex-direction: column;
    margin-bottom: 0; } }

.Footer__Link {
  &:hover {
    color: $color-primary; }

  @include max-w($mobile_md) {
    font-size: 16px;
    line-height: 19px; } }

.Footer__Label {
  font-size: 14px;
  line-height: 17px;
  color: $color-seventh; }

.Footer__Item {
  &:not(:last-child) {
    margin-bottom: 20px;

    @include max-w($mobile_md) {
      margin-bottom: 15px; } } }

.Footer__Col {
  display: flex;
  flex-direction: column;

  @include max-w($tablet) {
    width: 50%;
    margin-bottom: 35px; }

  @include max-w($mobile_md) {
    width: 100%; }

  &--AlignRight {
    align-items: flex-end;

    @include max-w($tablet) {
      align-items: flex-start; }

    .Footer__Label {
      margin-bottom: 10px; } } }

.Footer__TelCol {
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  &:hover {
    color: $color-primary; }

  svg {
    display: block;
    max-width: 20px;
    margin-right: 15px;
    height: auto; } }

.Footer__Bottom {
  display: flex;
  justify-content: space-between;

  @include max-w($mobile_md) {
    flex-direction: column; } }

.Footer__Bottom-Right {
  @include max-w($mobile_md) {
    order: -1; } }

.Footer__Payments {
  display: flex;
  align-items: center;
  margin-bottom: 27px; }

.Footer__Payment {
  &:not(:last-child) {
    margin-right: 30px; }

  &--VISA {
    .Footer--White & {
      path {
        &:first-child {
          fill: $color-background; }
        &:last-child {
          fill: #fff; } } } } }

.Footer__Copy {
  font-size: 14px;
  line-height: 17px;
  color: $color-seventh; }

.Footer__Info {
  //max-width: 300px
  font-size: 14px;
  line-height: 17px;
  text-align: right;

  @include max-w($mobile_md) {
    text-align: left;
    margin-bottom: 35px; }

  .Footer--White & {
    color: $color-seventh; }

  span {
    color: #5CA1FF; } }

.Footer__Disclaimer {
  font-size: 14px;
  line-height: 17px;
  color: $color-seventh;
  margin-top: 35px;
  text-align: center; }
