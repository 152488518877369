@import "../../../common/variables";
@import "../../../common/mixins";

.Settings {
  .Form__Row {
    margin-left: 0;
    margin-right: 0;
    @include max-w($mobile_md) {
      flex-direction: column;
      margin-block: 10px !important; } }
  .Form__Col {
    padding-left: 0;
    padding-right: 0;
    width: calc(50% - 12px);

    @include max-w($mobile_md) {
      padding: 0 !important;
      width: 100%;
      margin-block: 10px; } } }

.Settings__Boxes {
  display: flex;
  margin: -25px -15px;
  flex-wrap: wrap; }

.Settings__Box-Wrapper {
  padding: 25px 15px 39px;
  width: 50%;

  @include max-w($laptop_md) {
    width: 100%; } }

.Settings__Box {
  flex-direction: column;
  height: 100%; }

.Settings__Box-Title {
  margin-bottom: 25px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase; }
