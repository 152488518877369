@import "../../common/variables";
@import "../../common/mixins";

.Countries {
  background-color: $color-background;
  padding: 168px 0 70px;
  color: #fff;

  @include max-w($mobile_lg) {
    padding-top: 100px; } }

.Countries__Title {
  margin-bottom: 100px;
  position: relative;
  z-index: 1;

  &::after {
    @include fogginess(173, 157, 5s);
    content: '';
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: -1; }

  @include max-w($mobile_lg) {
    margin-bottom: 50px; } }

.Countries__Pulse-Icon {
  position: absolute;

  @include max-w($tablet) {
    display: none; }

  &--Earth {
    left: 10px;
    top: -20px; }

  &--China {
    left: 62%;
    top: -70px; }

  &--Euro {
    right: 30px;
    bottom: 10px; } }

.Country__Tabs {
  margin-bottom: 10px;

  @include max-w($tablet) {
    margin-bottom: 60px; } }

.Country__More {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px; }

.Country__Table-Body {
  @include max-w($tablet) {
    display: block; } }

.Country__Table-Head, .Country__Table-Col-Group {
  @include max-w($tablet) {
    display: none !important; } }
