@import "../../../../common/variables";
@import "../../../../common/mixins";

.Exchange_wrapper {
    display: flex;
    gap: 24px;
    & .table-orders {
        flex: 1; } }
.Empty-orders_item {
    margin-top: 15px;
    padding: 16px 0;
    color: #777E8A;
    font-size: 14px;
    text-align: center; }

.Transaction-Sell_table {
    margin-top: 50px; }
