@mixin fogginess($width, $height, $duration: 5s, $opacity: 1, $blur: 150) {
  width: #{$width}px;
  height: #{$height}px;
  opacity: $opacity;
  background: $color-fogginess;
  filter: blur(#{$blur}px);
  animation: fog-pulse $duration ease-in-out infinite;

  @keyframes fog-pulse {
    0% {
      filter: blur(130px)
    }
    50% {
      filter: blur(200px)
    }
    100% {
      filter: blur(130px)
    }
  }
}
