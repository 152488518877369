@import "../../common/variables";
@import "../../common/mixins";

.Header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 37px 0;
  z-index: 100;

  &--White {
    position: relative;
    background-color: $color-background-second; }

  @include max-w(905) {
    padding: 18px 0;

    &--Open {
      &::before {
        content: '';
        background-color: rgba(18, 25, 51, .4);
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%; } } } }

.Header__Nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;

  @include max-w(905) {
    left: 0;
    width: calc(100% + 3px);
    position: absolute;
    top: 0;
    padding: 100px 50px 50px;
    transform: translateY(-100%);
    justify-content: center;
    flex-direction: column;
    display: none;
    background: #10152B;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.57);

    .Header--White & {
      background-color: $color-background-second; }

    &::before {
      @include fogginess(154, 154);
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;

      .Header--White & {
        display: none; } }

    .Header--Open & {
      transform: translateY(0);
      display: block; } } }

.Header__List {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include max-w(905) {
    flex-direction: column;

    &:first-child {
      margin-bottom: 20px; } } }

.Header__Logo {
  display: block;
  position: relative;
  z-index: 101; }

.Header__Item {
  @include max-w(905) {
    margin-bottom: 30px;

    &.Logo {
      display: none; } } }

.Header__Link {
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  margin: 0 22px;

  &:hover {
    color: $color-primary; }

  .Header--White & {
    color: $color-background; }

  &--Active {
    cursor: default;
    color: $color-primary !important; } }

.Header__Button {
  display: block;
  margin-left: 24px;

  @include max-w(905) {
    margin-left: 0;
    order: -2; } }

.Header__Login {
  display: block;
  margin-left: 24px;

  @include max-w(905) {
    margin-left: 0;
    order: -1; } }
.Header__Mobile {
  align-items: center;
  justify-content: space-between;
  display: none;

  @include max-w(905) {
    display: flex; } }

.Header__Burger {
  position: relative;
  width: 30px;
  cursor: pointer;
  height: 21px;

  span {
    position: absolute;
    display: block;
    width: 30px;
    height: 3px;
    border-radius: 100px;
    background-color: #fff;
    transition: background-color 0.3s;

    .Header--White & {
      background-color: $color-background; }

    &:first-child {
      top: 0;
      transform: rotate(0);
      transition: top 0.3s 0.3s, transform 0.4s, background-color 0.4s; }

    &:nth-child(2) {
      top: 9px;
      transition: opacity 0.2s 0.3s, background-color 0.4s; }

    &:last-child {
      bottom: 0;
      transform: rotate(0);
      transition: 0.3s 0.3s, transform 0.4s, background-color 0.4s; } }

  .Header--Open & {
    span {
      &:first-child {
        top: 8px;
        transform: rotate(-45deg);
        transition: top 0.3s, transform 0.4s 0.3s; }

      &:nth-child(2) {
        top: 6px;
        opacity: 0;
        transition: opacity 0.3s; }

      &:last-child {
        bottom: 10px;
        transform: rotate(45deg);
        transition: top 0.3s, transform 0.4s 0.3s; } } } }

