@import "../../../../common/variables";
@import "../../../../common/mixins";


.faq__title {
    text-align: center;
    & h1 {
        font-size: 40px;
        line-height: 55px;
        margin: 0;
        @include max-w(768) {
            line-height: normal;
            margin-bottom: 21px; } } }

.faq__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-block: 31px;
    @include max-w(768) {
        flex-direction: column;
        padding-top: 0;
        gap: 21px;
        &>div {
            flex-direction: column; } }
    & .button__faq-back {
        position: absolute;
        left: 0;
        @include max-w(768) {
            position: relative; } }
    &>div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px; }
    & button {
        padding: 0 36px; } }
