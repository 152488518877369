@import "../../../../common/variables";
@import "../../../../common/mixins";

.main-form-cards {
    padding: 20px 0px !important;
    width: 100%;
    &_title1 {
        font-size: 20px;
        font-weight: 600; }
    &_subtitle1 {
        font-size: 18px;
        margin-top: 10px; }
    &_card {
        background: white;
        padding: 30px;
        border-radius: 41px;
        flex: 1;
        &_title {
            text-align: left;
            font-weight: bold;
            margin-top: 10px; } }
    &_action {
        display: flex;
        align-items: center;
        margin-top: 10px;
        &>div:first-of-type {
            font-size: 12px;
            line-height: 18px; }
        &>div:last-of-type {
            flex: 1;
            text-align: right; }
        @include max-w(1310) {
            flex-direction: column;
            align-items: flex-start;
            &>div:last-of-type {
                margin-top: 10px; } } }

    &_flex {
        margin-top: 40px !important;
        max-width: unset !important;
        gap: 30px !important;
        @include max-w(700) {
            flex-direction: column; } } }

.border {
    border: 1px solid red; }
.fit-content {
    flex: 0 !important;
    max-width: fit-content;
    margin: 0 auto;
    min-width: 560px;
    @include max-w(700) {
        min-width: auto; } }
.flex-1 {
    flex: 1; }

.btn-go-back {
    background: transparent;
    border: 0.7px solid #4d5368;
    padding-left: 20px;
    position: relative;
    font-weight: 400;
    font-size: 14px;
    line-height: 39px;
    text-transform: uppercase;
    color: #212842;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    &>img {
        filter: invert(1); } }

.gap-20 {
    gap: 20px; }

.gap-30 {
    gap: 30px; }

.white-form {
    background: transparent;
    width: 100%;
    padding: 20px 0; }
.btn-disabled {
    background: silver !important;
    color: #909090 !important;
    cursor: default !important; }

.physical_pin {
    @include max-w(534) {
        flex-direction: column; } }
.actions {
    @include max-w(534) {
        flex-direction: column-reverse; } }
