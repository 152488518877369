@import "../../common/variables";
@import "../../common/mixins";

.Dashboard {
  display: flex;
  min-height: 100vh;
  .Button {
    min-width: 120px;
    padding: 10px 20px 11px;
    font-size: 14px;
    line-height: 12px;

    &--Medium {
      font-size: 10px;
      line-height: 12px;
      padding: 8px 29px; }

    .Button__Icon {
      margin-right: 5px;

      svg {
        width: 15px;
        height: 15px; } } }

  .Table__Head {
    font-size: 12px;
    line-height: 12px; }

  .Exchange-Block {
    border-radius: 20px;

    &--Compact {
      padding: 12px 15px 20px; } }

  .Exchange-Block--Compact .Exchange-Block__Input {
    margin-bottom: 13px;

    &:nth-child(2) {
      margin-bottom: 20px; } }

  .Exchange-Block--Compact .Exchange-Block__Inner {
    margin-bottom: 20px; }

  .Exchange-Block__Input {
    .Exchange-Block--Compact & {
      margin-bottom: 13px;

      &:nth-child(2) {
        margin-bottom: 20px; } } }

  .Exchange-Block__Info {
    font-weight: 300;
    font-size: 12px;
    line-height: 12px;

    span {
      cursor: pointer;
      display: block;
      font-weight: 400;
      margin-top: 4px; } }

  .Form-Select-Simple__value-container {
    padding: 0 !important;

    .Form-Select-Simple__placeholder, .Form-Select-Simple__single-value {
      font-size: 14px;
      line-height: 17px;
      font-weight: 400; }

    .Form-Select--Small {
      font-size: 14px;
      line-height: 17px; } }

  .Form-Input {
    padding: 7px 10px 9px;
    font-weight: 400;
    height: 35px;
    font-size: 14px;
    line-height: 17px;

    .Form-Input__Label {
      margin-bottom: 9px;
      font-size: 10px;
      line-height: 14px; } }

  .Form-Input__Select {
    right: 11px;
    top: 26px; }

  .Dashboard .Form-Input__Label {
    margin-bottom: 10px;
    font-size: 10px;
    line-height: 12px; }

  .Table__Row {
    min-height: 68px; }

  .Table__Separator {
    margin-bottom: 15px; }

  .UploadItem {
    padding: 20px 20px 15px;

    .UploadItem__Head {
      margin-bottom: 10px;

      .UploadItem__Title {
        font-size: 14px;
        line-height: 23px; } }

    .UploadItem__Bottom {
      font-size: 12px;
      line-height: 17px; } }

  .Form__Row {
    justify-content: space-between;
    margin: 0 -15px;

    &--mb-40 {
      display: flex;
      align-items: center;
      margin-bottom: 25px; }

    &--mb-60 {
      margin-bottom: 44px; }

    &--Sm {
      padding: 0 15px; } }

  .Form__CheckboxLabel {
    margin-bottom: 4px;

    &--Dark {
      font-size: 14px;
      line-height: 17px; } }

  .Form-Input__Label {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 12px; }

  .Form-Input__Icon {
    top: 37px;
    right: 15px; }

  .SelectSingle__control {
    min-height: 35px !important;

    .SelectSingle__placeholder, .SelectSingle__single-value {
      font-size: 14px;
      line-height: 17px;
      font-weight: 300 !important; } }

  .UploadItem {
    padding: 20px 20px 15px;

    .UploadItem__Head {
      margin-bottom: 10px; } }

  .Settings__Box {

    .SelectSingle__control {
      height: 35px; } } }

.Dashboard__Aside {
  flex-shrink: 0;
  max-width: 325px;
  width: 100%;

  @include max-w($laptop_lg) {
    max-width: 276px; }

  @include max-w($tablet) {
    width: 0; } }

.Dashboard__Content {
  background-color: $color-background-second;
  padding: $dashboard_padding;
  width: calc(100% - 325px);
  @include max-w($laptop_lg) {
    width: calc(100% - 276px); }

  @include max-w($tablet) {
    padding: 100px 20px;
    width: 100%; }

  .ToolboxesBuy {
    .Button {
      min-width: 135px; } } }
.Activate__Card--wrapper {
  display: flex;
  align-items: center;
  & button {
    min-width: 100px; }
  @include max-w($mobile_md) {
    flex-direction: column;
    & button {
      align-self: flex-end;
      margin-top: 10px; } } }

.UploadItem {
  border: 0.7px solid rgba($color-background, .6) !important;
  border-radius: 6px;
  padding: 20px; }

.UploadItem__Head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: flex-start;

  .dropzone__image {
    max-height: 100px;
    margin-bottom: -5px; } }

.UploadItem__Title {
  font-size: 14px;
  line-height: 23px;
  text-transform: uppercase;
  max-width: 295px; }

.UploadItem__Icon {
  cursor: pointer; }

.UploadItem__Tooltip {
  position: relative; }

.UploadItem__Bottom {
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #777E8A;

  span {
    color: $color-primary;
    cursor: pointer; } }
