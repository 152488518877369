/*--------------------------------------------------------------
# header
--------------------------------------------------------------*/
.card__page-wrapper {
  background-color: var(--dark);

  color: var(--white);
  font-family: "MacPawFixelDisplay-Light";
  font-size: 18px;
  padding: 120px 0 108px;
}
.hero__background {
  background: transparent url(/img/card-bg.png) center 48% no-repeat;
  background-size: cover;
  padding-bottom: 237px;
  background-clip: border-box;
}
.card__hero-container {
  flex: 1;
}

.card__page-wrapper .h2 {
  font-family: "MacPawFixelDisplay-Bold";
  font-size: 96px;
  font-style: normal;
  line-height: 115%;
  text-transform: uppercase;
  margin: 0 0 45px;
}

@media (max-width: 992px) {
  .hero__background {
    padding: 46px 0 80px;
  }
}

@media (max-width: 768px) {
  .hero__background {
    background: transparent url(/img/card-bg-mob.png) center 60% no-repeat;
    background-size: cover;
    padding-bottom: 40px;
  }
  .card__page-wrapper {
    padding: 10px 0 0px;
  }
  .card__page-wrapper .h2 {
    font-size: 42px;
  }
}

.nav-dark__card {
  margin-bottom: 120px;
}

@media (max-width: 768px) {
  .nav-dark__card {
    margin-bottom: 60px;
  }
}

.nav__pages-card {
  margin: 0 40px;
}

.header__form-card {
  margin-right: 27px;
  margin-left: auto;
  position: relative;
}

.header__form-card::before {
  position: absolute;
  content: url(/img/flag-1.svg);
  top: 8px;
  left: 12px;
}

.exhange-field-card select {
  width: 137px;
  border: none;
  min-width: 90px;
  padding: 13px 16px 11px 38px;
  font-size: 18px;
  height: 100%;
  background-color: transparent;
  background-image: url(/img/select-arrow.svg);
  background-position: 91% 81%;
  background-repeat: no-repeat;
  color: var(--white);
  font-family: "MacPawFixelDisplay-Bold";
}

.exhange-field-card select:focus {
  outline: none;
}

@media (max-width: 768px) {
  .header__wrap {
    align-items: center;
    text-align: center;
  }
}

.header__title {
  margin: 0 0 45px;
}

@media (max-width: 992px) {
  .header__title {
    font-size: 86px !important;
  }
}

@media (max-width: 768px) {
  .header__title {
    font-size: 40px !important;
    text-align: center;
    padding-left: 17px;
  }
}

/*--------------------------------------------------------------
# end header
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# cards
--------------------------------------------------------------*/
.cards {
  padding: 50px 0;
}

@media (max-width: 768px) {
  .cards {
    padding: 25px 0;
  }
}

.cards__wrap {
  display: flex;
  justify-content: space-between;
  position: relative;
}

@media (max-width: 992px) {
  .cards__wrap {
    flex-direction: column;
  }
}

.cards__wrap::before {
  position: absolute;
  content: "";
  opacity: 0.3;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    #fff 13.58%,
    #fff 85.95%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
}

@media (max-width: 992px) {
  .cards__wrap::before {
    width: 100%;
    height: 1px;
    top: 56%;
    left: 0;
  }
}

@media (max-width: 768px) {
  .cards__wrap::before {
    top: 54%;
  }
}

.cards__item {
  display: flex;
  flex-direction: column;
  max-width: 469px;
  width: 100%;
  align-items: flex-start;
}

@media (max-width: 992px) {
  .cards__item:nth-child(1) {
    margin-bottom: 50px;
  }
}
@media (max-width: 992px) {
  .cards__item {
    padding: 0 10px;
    max-width: 80%;
  }
}

@media (max-width: 768px) {
  .cards__item {
    max-width: 100%;
    padding: 15px 0;
  }
}

.cards__item img {
  max-width: 469px;
  width: 100%;
  object-fit: cover;
  border-radius: 15.289px;
  box-shadow: 0px 5.65px 10.311px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 52px;
}

@media (max-width: 992px) {
  .cards__item img {
    margin: 0 auto 32px;
  }
}

.cards__box {
  margin-bottom: 44px;
}

.cards__title {
  font-size: 40px;
  text-transform: uppercase;
  line-height: normal;
  font-family: "MacPawFixelDisplay-ExtraBold";
  margin: 0 0 6px;
}

@media (max-width: 768px) {
  .cards__title {
    font-size: 20px;
  }
}

.cards__slogan {
  font-size: 22px;
  font-family: "MacPawFixelDisplay-Regular";
  line-height: normal;
}

@media (max-width: 768px) {
  .cards__slogan {
    font-size: 18px;
  }
}

.cards-list {
  display: flex;
  flex-direction: column;
  gap: 44px;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 59px;
}

@media (max-width: 768px) {
  .cards-list {
    gap: 24px;
    margin-bottom: 40px;
  }
}

.cards-list__item {
  font-size: 22px;
  font-family: "MacPawFixelDisplay-Regular";
  line-height: normal;
  padding-left: 50px;
  position: relative;
}

@media (max-width: 768px) {
  .cards-list__item {
    font-size: 18px;
  }
}

.cards-list__item::before {
  position: absolute;
  content: url(/img/item-cards.svg);
  top: -2px;
  left: -1px;
}
/*--------------------------------------------------------------
# end cards
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# secure
--------------------------------------------------------------*/
.secure {
  padding: 58px 0 80px;
}

@media (max-width: 768px) {
  .secure {
    padding: 28px 0 30px;
  }
}

.secure__title {
  margin: 0 0 83px;
}

@media (max-width: 768px) {
  .secure__title {
    font-size: 40px !important;
    text-align: center;
    margin: 0 0 43px;
  }
}

.secure-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 26px 226px;
}

@media (max-width: 1200px) {
  .secure-list {
    gap: 60px 100px;
  }
}

@media (max-width: 992px) {
  .secure-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .secure-list {
    grid-template-columns: repeat(1, 1fr);
    gap: 60px;
  }
}

.secure-list__item {
  max-width: 240px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .secure-list__item {
    max-width: 100%;
  }
}

.secure-list__item img {
  margin-bottom: 11px;
  max-width: 238px;
}

@media (max-width: 768px) {
  .secure-list__item img {
    margin: 0 auto 11px;
  }
}

.secure-list__title {
  font-size: 22px;
  font-family: "MacPawFixelDisplay-ExtraBold";
  line-height: normal;
  text-transform: uppercase;
  margin: 0 0 6px;
}

@media (max-width: 768px) {
  .secure-list__title {
    font-size: 20px;
  }
}

.secure-list__text {
  font-size: 22px;
  font-family: "MacPawFixelDisplay-Regular";
  line-height: normal;
}

@media (max-width: 768px) {
  .secure-list__text {
    font-size: 18px;
  }
}
/*--------------------------------------------------------------
# end secure
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# purch
--------------------------------------------------------------*/
.purch {
  padding: 51px 0 65px;
}

@media (max-width: 768px) {
  .purch {
    padding: 31px 0 60px;
  }
}

.purch__wrap {
  position: relative;
}

@media (max-width: 768px) {
  .purch__wrap {
    text-align: center;
  }
}

.purch__position {
  display: block;
  position: absolute;
  content: "";
  max-width: 470px;
  width: 100%;
  height: 450px;
  right: 10px;
  top: 25%;
}

@media (max-width: 1124px) {
  .purch__position {
    position: relative;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .purch__position {
    margin: 0 auto 90px;
    height: 300px;
  }
}

@media (max-width: 375px) {
  .purch__position {
    margin: 0 auto 30px;
    height: 270px;
  }
}

.purch__position img {
  min-width: 277px;
}

.purch__position img:nth-child(1) {
  transform: rotate(4deg);
}

.purch__position img:nth-child(2) {
  transform: translateY(-232px) rotate(-4deg) translateX(15px);
}

@media (max-width: 375px) {
  .purch__position img:nth-child(2) {
    transform: translateY(-180px) rotate(-5deg) translateX(10px);
  }
}

.purch__title {
  margin: 0 0 12px;
}

@media (max-width: 768px) {
  .purch__title {
    font-size: 40px;
    margin: 0 0 40px;
    text-align: center;
  }
}

.purch__description {
  font-size: 22px;
  line-height: normal;
  font-family: "MacPawFixelDisplay-Regular";
  margin-bottom: 47px;
  max-width: 500px;
  width: 100%;
}

@media (max-width: 768px) {
  .purch__description {
    font-size: 18px;
    text-align: left;
  }
}

.purch__btn {
  min-width: 219px;
}
/*--------------------------------------------------------------
# end purch
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# end work
--------------------------------------------------------------*/
.work {
  padding: 58px 0;
}

@media (max-width: 768px) {
  .work {
    padding: 32px 0;
  }
}

.work__wrap {
  background-image: url(/img/cards-bg.png);
  background-repeat: no-repeat;
  background-position: center 80%;
  background-size: 131% 370px;
}

@media (max-width: 768px) {
  .work__wrap {
    text-align: center;
    background-position: center 62%;
    background-size: 242% 370px;
  }
}

.work__title {
  margin: 0 0 63px;
}

@media (max-width: 768px) {
  .work__title {
    font-size: 40px;
    margin: 0 0 40px;
  }
}

.work-list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  justify-content: space-between;
  gap: 10px;
  align-items: flex-start;
}

@media (max-width: 768px) {
  .work-list {
    flex-direction: column;
    gap: 40px;
    text-align: left;
  }
}

.work-list__item {
  max-width: 370px;
  width: 100%;
}

@media (max-width: 768px) {
  .work-list__item {
    max-width: 100%;
  }
}

.work-list__item img {
  margin-bottom: 4px;
}

.work-list__item:nth-child(1),
.work-list__item:nth-child(3) {
  margin-top: 88px;
}

@media (max-width: 768px) {
  .work-list__item:nth-child(1),
  .work-list__item:nth-child(3) {
    margin-top: 0;
  }
  .work-list__item:nth-child(2) {
    text-align: right !important;
  }

  .work-list__item img {
    display: inline;
  }
}

.work-list__title {
  font-size: 22px;
  font-family: "MacPawFixelDisplay-ExtraBold";
  line-height: normal;
  text-transform: uppercase;
  margin: 0 0 6px;
}

@media (max-width: 768px) {
  .work-list__title {
    font-size: 20px;
  }
}

.work-list__text {
  font-size: 22px;
  font-family: "MacPawFixelDisplay-Regular";
  line-height: normal;
}

@media (max-width: 768px) {
  .work-list__text {
    font-size: 18px;
  }
}
/*--------------------------------------------------------------
# end work
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#  faq
--------------------------------------------------------------*/
.card__faq {
  padding: 47px 0;
}

@media (max-width: 768px) {
  .card__faq {
    padding: 25px 0;
  }
}

.faq__title {
  margin: 0 0 57px;
}

@media (max-width: 768px) {
  .faq__title {
    font-size: 40px;
    margin: 0 0 40px;
    text-align: center;
  }
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 37px;
  margin: 0 0 67px;
  padding: 0;
  list-style: none;
}

.card__page-wrapper .faq-list__item {
  padding-bottom: 32px;
  border-bottom: 1px solid #ffffff44;
}

.faq-list__box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 35px;
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .faq-list__box {
    gap: 5px;
  }
}

.faq-list__title {
  font-size: 22px;
  font-family: "MacPawFixelDisplay-Regular";
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

@media (max-width: 768px) {
  .faq-list__title {
    font-size: 18px;
  }
}

.faq-list__link {
  width: unset !important;
  padding: 5px 7px 0 0;
}

.faq-list__link img {
  min-width: 24px;
}

.faq-list__text {
  font-size: 18px;
  font-family: "MacPawFixelDisplay-Regular";
  line-height: normal;
  opacity: 0.5;
  max-width: 95%;
}

.faq-bottom__title {
  font-size: 22px;
  font-family: "MacPawFixelDisplay-Bold";
  line-height: normal;
  text-transform: uppercase;
  margin: 0 0 6px;
}

@media (max-width: 768px) {
  .faq-bottom__title {
    font-size: 20px;
  }
}

.faq-bottom__text {
  font-size: 22px;
  font-family: "MacPawFixelDisplay-Regular";
  font-weight: 400;
  line-height: normal;
  color: inherit !important;
}

@media (max-width: 768px) {
  .faq-bottom__text {
    font-size: 18px;
  }
}

.faq-bottom__text a {
  color: var(--secondary-purple);
}
/*--------------------------------------------------------------
# end faq
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# sing-up
--------------------------------------------------------------*/
.sing-up {
  padding: 61px 0 0px;
}

@media (max-width: 768px) {
  .sing-up {
    padding: 25px 0;
  }
}

.sing-up__title {
  margin: 0 0 47px;
}

@media (max-width: 768px) {
  .sing-up__title {
    font-size: 40px;
    margin: 0 0 35px;
    text-align: center;
  }
}

.sing-up-form {
  padding: 35px 50px 55px;
  border-radius: 20px;
  background: linear-gradient(0deg, #121212 0%, #121212 100%), #fff;
  box-shadow: 0px 4px 117px 0px rgba(0, 0, 0, 0.03);
  text-align: left;
  width: 100%;
}

@media (max-width: 768px) {
  .sing-up-form {
    padding: 38px 24px;
    text-align: center;
  }
}

.sing-up__box-label {
  display: flex;
  margin-bottom: 38px;
  gap: 56px;
}

@media (max-width: 992px) {
  .sing-up__box-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 40px;
  }
}

@media (max-width: 768px) {
  .sing-up__box-label {
    text-align: left;
    gap: 19px;
    margin-bottom: 35px;
  }
}

.singup-register {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 47px;
  padding: 4px 0 0 4px;
}

@media (max-width: 768px) {
  .singup-register {
    text-align: left;
    padding-top: 0;
    margin-left: 2px;
    margin-bottom: 40px;
  }
}

.singup-register__label {
  font-size: 22px;
  font-family: "MacPawFixelDisplay-Regular";
  padding-left: 35px;
  line-height: normal;
  position: relative;
}

@media (max-width: 768px) {
  .singup-register__label {
    font-size: 18px;
    display: block;
    margin-bottom: -2px;
  }
}

.singup-register__label a {
  color: var(--secondary-purple);
}

@media (max-width: 768px) {
  .singup-register__label:nth-child(3) label a {
    display: block;
  }
}

.captcha {
  margin-bottom: 49px;
}

@media (max-width: 768px) {
  .captcha {
    margin-bottom: 40px;
  }
}
.sing-up .paper {
  padding: 0 !important;
  background-color: transparent;
  margin-bottom: 0;
}
.sing-up .BSelectLarge {
  background: #121212;
  border: 1px solid rgba(255, 255, 255, 0.9);
}
.sing-up .BSelectLarge input {
  color: white;
}
.sing-up .show input {
  color: var(--dark);
}
.sing-up span:has(+ .dropdown) {
  color: rgba(255, 255, 255, 0.6);
}
.sing-up .register_wrapper {
  padding: 0;
}
.sing-up__wrap .container {
  padding: 0 !important;
  max-width: unset !important;
}
.sing-up .register__grid .select_sex {
  order: 1000;
}
/*--------------------------------------------------------------
# end sing-up
--------------------------------------------------------------*/
