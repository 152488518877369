@import "../../../common/variables";
@import "../../../common/mixins";

.ToolboxesChartBox {
  margin-bottom: $bottom_padding;
  padding: 20px 30px;

  @include max-w($mobile_lg) {
    margin-bottom: 40px; } }

.ToolboxesChart__Head {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid #C4C4C4;
  padding-bottom: 11px;
  margin-bottom: 20px;

  @include max-w($mobile_lg) {
    flex-direction: column;
    align-items: center;
    justify-content: center; } }

.ToolboxesChart__Currencies {
  display: flex;
  flex-direction: column;
  margin-right: 84px;

  @include max-w($mobile_lg) {
    margin-right: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px; } }

.ToolboxesChart__SmallSelect {
  display: flex;
  margin-bottom: -10px;

  @include max-w($mobile_lg) {
    margin-bottom: -10px; } }

.ToolboxesChart__Select {
  display: flex;
  align-items: center;
  font-size: 19px;
  line-height: 23px;
  font-weight: 500;

  .Form-Select {
    .Form-Select-Simple__placeholder, .Form-Select-Simple__single-value {
      font-size: 19px !important;
      line-height: 23px !important;
      font-weight: 500 !important; } }

  @include max-w($mobile_lg) {
    font-size: 18px;
    line-height: 21px; } }

.ToolboxesChart__Info {
  padding-top: 8px;

  @include max-w($mobile_lg) {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0; } }

.ToolboxesChart__Label {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: $color-seventh;
  margin-bottom: 10px;
  text-transform: uppercase;

  @include max-w($mobile_lg) {
    font-size: 14px;
    line-height: 17px; } }

.ToolboxesChart__Value {
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  display: flex;
  align-items: center;

  @include max-w($mobile_lg) {
    font-size: 18px;
    line-height: 21px; } }

.ToolboxesChart__Rate {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #E41F20;
  display: flex;
  align-items: center;
  margin-left: 10px;

  svg {
    margin-right: 3px; }

  @include max-w($mobile_lg) {
    font-size: 12px;
    line-height: 14px; }

  &.ToolboxesChart__Rate_Up {
    color: green; } }

.ToolboxesBottom {
  display: flex;

  @include max-w($desktop_sm) {
    flex-wrap: wrap; } }

.ToolboxesInfoBox {
  flex-grow: 1;
  margin-right: 20px;
  padding: 29px 32px;
  @include max-w($mobile_md) {
    padding: 29px 20px; }
  @include max-w($desktop_sm) {
    width: 100%;
    margin-right: 0;
    margin-bottom: $bottom_padding; } }

.ToolboxesInfo {
  @include max-w($mobile_lg) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; } }

.ToolboxesInfo__Button {
  @include max-w($mobile_lg) {
    margin-top: 20px; } }

.ToolboxesInfo__Title {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 5px;
  text-transform: uppercase;

  @include max-w($mobile_lg) {
    margin-bottom: 30px; } }

.ToolboxesInfo__Items {
  display: flex;
  margin: 0 -15px -1px -15px;
  flex-wrap: wrap; }

.ToolboxesInfo__Item-Wrap {
  padding: 16px 15px 16px 15px;
  width: 25%;

  @include max-w($mobile_lg) {
    width: 100%;
    text-align: center;
    padding: 10px 15px; } }

.ToolboxesInfo__Item {
  width: 100%;

  @include max-w($mobile_lg) {
    display: flex; } }

.ToolboxesInfo__Item-Value {
  font-size: 14px;
  line-height: 17px;
  color: #212946;
  opacity: 0.9;
  word-break: break-all;

  @include max-w($mobile_lg) {
    font-size: 16px;
    line-height: 19px; } }

.ToolboxesInfo__Item-Label {
  font-size: 14px;
  color: #383F59;
  line-height: 12px;
  margin-bottom: 8px;

  @include max-w($mobile_lg) {
    margin-bottom: 0;
    color: $color-background;
    font-size: 16px;
    line-height: 19px;
    margin-right: 10px; } }

.ToolboxesBuy {
  background-color: $color-primary;
  border-radius: 25px;
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 40px 26px;
  color: #fff;
  justify-content: center;
  text-align: center;
  flex-shrink: 0;
  max-width: 337px;
  width: 100%;

  @include max-w($desktop_sm) {
    width: 100%;
    margin: 0 auto;
    max-width: 100%; } }

.ToolboxesBuy__Image {
  width: 68px;
  height: 60px;
  margin-bottom: 24px; }

.ToolboxesBuy__Title {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 8px;
  text-transform: uppercase; }

.ToolboxesBuy__Desc {
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 17px; }

.ToolboxesBuy__Button {
  color: #11172F;
  min-width: 135px; }

.ToolboxesChart {
  width: 100%; }

.ToolboxesChart__TimeFrame {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
  font-size: 13px;
  line-height: 12px;

  button {
    border: none;
    border-radius: 10px;
    background: transparent;
    padding: 13px;
    margin: 0 4px;
    cursor: pointer;
    font-weight: normal;
    font-family: $font-family-base;

    &.active {
      color: #fff;
      cursor: default;
      background-color: $color-primary; }

    &:last-child {
      margin-right: 0; }

    &:first-child {
      margin-left: 0; }

    @include max-w($desktop_sm) {
      padding: 7px; } } }

.ToolboxesChart__Graph canvas {
  max-height: 185px; }

.Dashboard_Chart {
  width: calc( 100% - 280px );
  @include max-w(1300) {
    width: 100%; } }
