@import "../../../common/variables";
@import "../../../common/mixins";

.CountriesItem__Row {
  @include max-w($tablet) {
    display: flex !important;
    margin-bottom: 30px;
    overflow: hidden;
    background: #222E52;
    flex-wrap: wrap;
    border-radius: 16px; } }

.CountriesItem__Cell {
  span:not(.CountriesItem__Label) {
    display: inline-block;
    vertical-align: middle;

    svg {
      display: block; } }

  &:first-child {
    @include max-w($tablet) {
      border-radius: 0; } }

  &:last-child {
    @include max-w($tablet) {
      border-radius: 0; } }

  @include max-w($tablet) {
    display: block;
    position: relative;
    width: 100%;
    padding: 10px 40px;

    &:first-child {
      padding-top: 20px;
      padding-bottom: 20px;

      .Table__Cell-label {
        display: none; } }

    &:last-child {
      padding-bottom: 20px; }

    &:not(:first-child) {
      display: none; }

    &--MobileSm {
      width: 20px; }

    .CountriesItem__Row--Open & {
      display: block;

      &:first-child {
        padding-bottom: 10px;

        .Table__Cell-label {
          display: block; } } } } }

.CountriesItem__Separator {
  @include max-w($tablet) {
    display: none; } }

.CountriesItem__Label {
  display: none;
  font-size: 14px;
  line-height: 17px;
  color: $color-seventh;
  margin-bottom: 10px;

  .CountriesItem__Row--Open & {
    @include max-w($tablet) {
      display: block;
      width: 100%; } } }

.CountriesItem__Icon {
  margin-right: 15px;

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%; } }

.CountriesItem__Arrow {
  position: absolute;
  right: 40px;
  top: 30px;
  transition: transform .5s;
  display: none;

  @include max-w($tablet) {
    display: block; }

  .CountriesItem__Row--Open & {
    transform: rotate(180deg); } }
