.faq__paper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 39px 46px 54px;
  text-align: left;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 4px 117px 0px rgba(0, 0, 0, 0.03);
  position: relative;
}
.faq__paper::after {
  position: absolute;
  content: url(/img/fag-after.svg);
  bottom: 0;
  right: 0;
}
.faq-title span {
  font-family: "MacPawFixelDisplay-SemiBold";
  font-size: 22px;
  border-radius: 49px;
  background-color: rgba(84, 5, 214, 0.2);
  width: 39px;
  line-height: normal;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.faq-title {
  display: flex;
  align-items: center;
  gap: 10px;
}
.faq-title,
.faq-subtitle {
  font-family: "MacPawFixelDisplay-SemiBold";
  font-size: 22px;
  line-height: normal;
  margin: 0;
}

.faq-content__box-title {
  margin-bottom: 25px;
}
.faq-list {
  display: flex;
  flex-direction: column;
  gap: 23px;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-right: 23px;
  margin-bottom: 42px;
  flex: 1;
}
.faq-content__item:nth-child(1) .faq-list__item {
  min-height: 60px;
}
.faq-list__item {
  position: relative;
  padding-left: 24px;
}
.faq-list__item::before {
  position: absolute;
  content: "";
  width: 9px;
  height: 9px;
  background-color: var(--dark);
  border-radius: 50%;
  top: 34%;
  left: 0;
}
.faq-list__link {
  font-family: "MacPawFixelDisplay-Regular";
  font-size: 22px;
  line-height: normal;
  color: var(--dark);
  display: inline-flex;
  width: 100%;
  transition: all 0.5s;
}
@media (max-width: 768px) {
  .faq__paper {
    padding: 45px 25px 59px;
  }
  .faq-list__link {
    font-size: 18px;
  }
  .faq-title {
    font-size: 18px;
  }
  .faq-title span {
    font-size: 18px;
  }
}
.faq-list__link:hover {
  text-decoration: underline;
}
