@import "../../common/variables";
@import "../../common/mixins";

.Page_Wrapper {
  background-color: $color-background-second;
  color: $color-text-second;
  padding-top: 46px;
  &__Logo {
    background-color: $color-background-second;
    padding: 20px 0 0;
    display: flex;
    justify-content: center; } }
.instruction-qr-code {
  @include max-w($mobile_lg) {
    margin-block: 21px;
    height: auto !important; } }
.outer-tip {
  font-size: 16px;
  display: none;
  color: red;
  text-align: center;
  @include max-w($mobile_lg) {
    display: block; } }
.inner-tip {
  @include max-w($mobile_lg) {
    display: none; } }

.anchor-email {
  color: #5ca1ff; }

.transaction-hash {
  display: block;
  max-width: 600px;
  & .Form-InputBox {
    flex: 1; }
  & .transaction-hash-form {
    @include max-w($mobile_lg) {
      flex-direction: column;
      & button {
        width: 100%; } } } }
.received-status {
  & p {
    margin: 20px 0 0; }
  & a {
    font-weight: bold; } }
.to_dashboard {
  width: 220px !important; }
.note-tip {
  display: block;
  font-size: 16px;
  color: red;
  margin-top: 20px; }
