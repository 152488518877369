@import "../../common/variables";
@import "../../common/mixins";

.Form-Checkbox {
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    font-size: 12px;
    line-height: 150%;
    user-select: none;
    padding: 0 0 0 40px;
    text-align: left; }

  & + label a {
    color: $color-primary;

    &:hover {
      text-decoration: underline; } }

  & + label::before {
    content: '';
    border: 0.7px solid rgba($color-background, .8);
    border-radius: 3px;
    width: 25px;
    height: 25px;
    position: absolute;
    left: 0;
    top: -5px; }

  &:checked + label::after {
    content: '';
    position: absolute;
    left: 6px;
    top: 2px;
    width: 15px;
    height: 12px;
    background-image: url(check.svg);
    background-repeat: no-repeat; } }
.Form-Checkbox--Light {
  & + label::before {
    border-color: white; }
  &:checked + label::after {
    background-image: url(check-white.svg) !important; } }
