/*--------------------------------------------------------------
# Bootstrap style
--------------------------------------------------------------*/

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: 1200px;
}

/*--------------------------------------------------------------
# End Bootstrap style
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# general style
--------------------------------------------------------------*/

*:focus {
  outline: none !important;
}

/* buttons */

.btn-reset {
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
}

.btn-sq {
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  height: 40px;
  padding: 0 16px;
  border-radius: 6px;
}

.btn-long {
  width: 115px;
  text-align: center;
  padding: 0;
}

.btn-sq-br {
  border: 1px solid #78ff73;
  background-color: transparent;
  line-height: 38px;
  color: #78ff73;
}

.btn-sq-br:hover,
.btn-sq-br:focus,
.btn-sq-br:active {
  border-color: #fff;
  color: #fff;
}

.btn-nm {
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;
  height: 42px;
  padding: 0 40px;
  filter: drop-shadow(3px 4px 0px rgba(0, 0, 0, 0.5));
  position: relative;
  z-index: 0;
}

@media (max-width: 575.98px) {
  .btn-nm {
    font-size: 15px;
    line-height: 20px;
    height: 20px;
    padding: 0 20px;
  }
}

.btn-green {
  background-color: #78ff73;
  color: #000;
}

.btn-grey {
  background-color: #d5d5d5;
  color: #ffffff;
}
.btn-dark-grey {
  background-color: #9a9a9a;
  color: white;
}

.btn-grey-bg {
  background: #ffffff;
  color: #777e8a;
  border: 1px solid #777e8a;
}

.btn-trpr {
  background-color: transparent;
  color: #000;
}

.btn-white {
  background-color: #ffffff;
  color: #000;
}

.btn-blue {
  background-color: #5ca1ff;
  color: #ffffff;
}

.btn-blue:hover,
.btn-blue:focus,
.btn-blue:active {
  color: #ffffff;
  opacity: 0.8;
}

.btn-green:hover,
.btn-green:focus,
.btn-green:active,
.btn-trpr:hover,
.btn-trpr:focus,
.btn-trpr:active,
.btn-white:hover,
.btn-white:focus,
.btn-white:active {
  color: #000;
  opacity: 0.8;
}

.btn-red {
  color: #ffffff;
  background: #ef382a;
}
.deposit-btn {
  transition: none !important;
}
.disabled {
  color: silver;
  /* background: #F5FAFF !important; */
}
.error-feed {
  color: #ef382a !important;
}
.error-item {
  border: 1px solid #ef382a !important;
}
.btn-octagon::before,
.btn-octagon::after {
  content: "";
  position: absolute;
  z-index: 0;
  width: 100%;
  left: 0;
  right: 0;
}

.btn-nm::before {
  bottom: 100%;
  height: 17px;
}

@media (max-width: 575.98px) {
  .btn-nm::before {
    height: 8px;
  }
}

.btn-green::after,
.btn-green::before {
  border: 16px solid transparent;
  border-bottom: 16px solid #78ff73;
}

@media (max-width: 575.98px) {
  .btn-green::after,
  .btn-green::before {
    border: 8px solid transparent;
    border-bottom: 8px solid #78ff73;
  }
}

.btn-nm::after {
  top: 100%;
  height: 17px;
}

@media (max-width: 575.98px) {
  .btn-nm::after {
    height: 8px;
  }
}

.btn-green::after {
  transform: rotate(180deg);
}

.btn-back {
  background: transparent;
  border: 0.7px solid #4d5368;
  padding-left: 20px;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 39px;
  text-transform: uppercase;
  color: #212842;
}

.btn-back-outlined {
  background: transparent;
  border: 0.7px solid #4d5368;
  padding-left: 20px;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 39px;
  color: #212842;
}

.btn-back:before {
  content: "";
  width: 10px;
  height: 16px;
  position: absolute;
  top: calc(50% - 8px);
  left: 38px;
  background-image: url(@/Images/cards_images/back.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
  filter: invert(1);
}

/* end buttons */

/*octagon*/

.octagon {
  position: relative;
  z-index: 0;
  border-radius: 50px;
}
.octagon-whit {
  background: #ffffff;
}

/*end octagon*/

/*custom radio&checkbox*/

.custom-control {
  padding-left: 35px;
}

.custom-control-label {
  font-weight: 500;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  padding-top: 5px;
}

.custom-control-label a {
  color: #78ff73;
}

.custom-control-label::before {
  width: 20px;
  height: 20px;
  border-color: #78ff73;
  background-color: transparent;
  left: -35px;
}

.custom-control-label:hover::before {
  border-color: #9da6b7;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #78ff73;
}

.custom-control-input:active ~ .custom-control-label::before {
  background-color: #fff !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #78ff73;
  background-color: #78ff73;
}

/*radio button*/

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #000;
  top: 9px;
  bottom: 0;
  left: -30px;
  right: 0;
}

/*checkbox button*/

.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}

.custom-radio .custom-control-label::after,
.custom-checkbox .custom-control-label::after {
  width: 21px;
  height: 21px;
  left: -35px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("@/Images/cards_images/check.svg");
}

.input {
  background: #fff;
  font-size: 14px;
  line-height: 40px;
  height: 40px;
  font-weight: 600;
  padding: 0 20px;
  color: #000;
  border: none;
  outline: none;
  width: 100%;
}

.input-brdr {
  border: 1px solid #c3ced7;
}

.input-brdr:hover,
.input-brdr:focus {
  border-color: #000;
  transition: all 0.3s;
}

select.input {
  padding-right: 25px;
  background-image: url(@/Images/cards_images/drop-grey-sm.svg);
  background-position: 93% center;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.label {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 15px;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.no-decorate:hover {
  text-decoration: none;
}

a,
button {
  transition: all 0.1s linear;
  display: inline-block;
}

.h2 {
  font-weight: 400;
  font-size: 66px;
  line-height: 76px;
  text-transform: uppercase;
}

.h2 > span {
  color: #78ff73;
}

@media (max-width: 767.98px) {
  .h2 {
    font-size: 24px;
    line-height: 28px;
  }
}

.h3 {
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
}

.slogan {
  font-size: 28px;
  line-height: 44px;
  text-align: center;
  color: #78ff73;
  margin-bottom: 142px;
}

@media (max-width: 767.98px) {
  .slogan {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 80px;
  }
}

@media (max-width: 575.98px) {
  .slogan {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 30px;
  }
}

.shadows {
  position: relative;
}

.shadows::after {
  content: "";
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 75px;
  left: 0;
  right: 0;
  bottom: -75px;
  top: auto;
  background: linear-gradient(180deg, #23007c 0%, rgba(71, 0, 249, 0.0001) 100%);
  opacity: 0.4;
}

.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
}

.popover {
  filter: drop-shadow(25px 25px 40px rgba(16, 39, 83, 0.6));
  border-radius: 0;
  border: none;
}

.popover-header {
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  background-color: transparent;
  padding: 15px 20px;
}

.popover-body {
  padding: 20px 20px;
}

.popover ul {
  padding-left: 15px;
  margin: 0;
}

.modal {
  background: rgba(0, 0, 0, 0.7);
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 440px;
  }

  body > .widget-visible iframe {
    bottom: 100px !important;
  }
}

.modal-content {
  padding: 30px;
  border-radius: 21px;
}

.modal-header {
  border: none;
  padding: 0;
}

.modal-header .close {
  position: absolute;
  top: 25px;
  right: 28px;
  left: auto;
  opacity: 1;
  transition: all 0.3s ease;
}

.modal-header .close:hover path {
  fill: #000;
}

.modal-title {
  margin-bottom: 10px;
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #121933;
}

.modal-body {
  padding: 0;
}

.modal-body p {
  font-weight: 400;
  font-size: 12px;
  line-height: 107%;
  margin-bottom: 30px;
  width: 88%;
  color: #212946;
}

.verification-notice .modal-text {
  margin-bottom: 40px;
}

.verification-notice .modal-btns__butt {
  min-width: 158px;
  display: block;
  margin: 0 auto;
}

.verification-notice .btn-sq-br {
  color: #0d1118;
  border-color: #c3ced7;
  margin-right: 20px;
}

@media (max-width: 767.98px) {
  .verification-notice .btn-sq-br {
    margin: 0 auto 20px;
  }
}

@media (max-width: 991.98px) {
  .notification {
    margin-bottom: 40px;
  }
}

.notification__caption {
  font-weight: 600;
  font-size: 28px;
  line-height: 37px;
  margin-bottom: 38px;
}

.notification-box {
  background-color: #fff;
  padding: 0px 30px;
}

@media (max-width: 991.98px) {
  .notification-box {
    padding: 0px 30px;
  }
}

.notification__icon {
  width: 57px;
  height: 57px;
  display: block;
  margin: 0 auto 20px;
}

.notification__title {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.56;
  text-transform: uppercase;
  color: #0d1118;
  margin-bottom: 10px;
}

.notification__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.45;
  color: #777e8a;
  margin-bottom: 20px;
}
.notification__text:last-child {
  margin-bottom: 0;
}
.link-green:hover,
.link-green:active,
.link-green:focus,
.link-green {
  color: #78ff73;
}

.link-blue:hover,
.link-blue:active,
.link-blue:focus,
.link-blue {
  color: #2048e6;
}

.div-h1 {
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 1.2;
  color: #000000;
}

@media (max-width: 1300px) {
  .div-h1 {
    font-size: 18px;
  }
}

@media (max-width: 575.98px) {
  .div-h1 {
    width: 100%;
  }
}

/* ::-webkit-scrollbar-button {
  width: 6px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 13px;
  -webkit-border-radius: 13px;
  width: 6px;
}

::-webkit-resizer {
  width: 6px;
  height: 0px;
}

::-webkit-scrollbar {
  width: 6px;
} */

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}
.mt40 {
  margin-top: 40px;
}
.mt-20 {
  margin-top: -20px;
}

/*--------------------------------------------------------------
# end general style
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# footer style
--------------------------------------------------------------*/

.footer {
  padding-top: 5px;
  padding-bottom: 20px;
  color: #fff;
  background: #f5faff;
}

@media (max-width: 575.98px) {
  .footer-row > div {
    width: 50%;
  }
  .footer__cell_mob {
    width: 33.33%;
  }
}

.footer-info,
.footer__item {
  margin-bottom: 45px;
}
.footer-info {
  text-align: right;
}
@media (max-width: 991.98px) {
  .footer-info {
    text-align: left;
  }
}
@media (max-width: 575.98px) {
  .footer-info {
    margin-bottom: 40px;
    text-align: center;
  }
  .footer__item {
    margin-bottom: 25px;
    text-align: center;
  }
}

.footer__title {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #9a9a9a;
  margin-bottom: 20px;
}

@media (max-width: 575.98px) {
  .footer__title {
    text-align: left;
    margin-bottom: 10px;
  }
}
.footer__social {
  display: flex;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  gap: 18px;
}
@media (max-width: 575.98px) {
  .footer__social {
    justify-content: center;
  }
}
.footer__social li {
  padding: 0;
  margin: 0;
  line-height: 1;
  list-style: none;
}
.footer__social li a {
  padding: 8px;
  margin: 0;
  line-height: 1;
  background: #1d2744;
  border-radius: 5px;
}
.footer__social li a:hover {
  background: #5ca1ff;
}
.footer__social li img {
  width: 25px;
  height: auto;
}
@media (max-width: 575.98px) {
  .footer__social li img {
    width: 15px;
  }
}
.footer__link {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #121933;
  margin-bottom: 20px;
}

@media (max-width: 575.98px) {
  .footer__link {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 10px;
    text-align: left;
    display: block;
    font-family: "MacPawFixelDisplay-Light" !important;
  }
}

.footer-info__tel:focus,
.footer-info__tel:active,
.footer-info__tel:hover,
.footer__link:hover,
.footer__link:active,
.footer__link:focus {
  text-decoration: underline;
  color: #5ca1ff;
}

.footer-info__img {
  display: block;
  margin-bottom: 29px;
}

@media (max-width: 575.98px) {
  .footer-info__img {
    margin: 0 auto 10px;
  }
}

.footer-info__tel {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: right;
  color: #121933;
  vertical-align: baseline;
  margin-bottom: 25px;
  display: block;
}

@media (max-width: 991.98px) {
  .footer-info__tel {
    text-align: left;
  }
}
.footer-info__tel img {
  width: 20px;
  height: auto;
  margin-right: 15px;
  vertical-align: baseline;
}

.footer-info__date {
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 0;
}

@media (max-width: 575.98px) {
  .footer-info__date,
  .footer-info__tel {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 10px;
  }
}
.footer-info__email {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #121933;
  margin-top: -10px;
  display: block;
}
@media (max-width: 575.98px) {
  .footer-info__email {
    font-size: 14px;
    line-height: 1.5;
    text-align: left;
    margin-top: -5px;
  }
}
.footer__license {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #c7c7c7;
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  .footer__license {
    text-align: center;
  }
}
.footer__license > span {
  color: #5ca1ff;
}

@media (max-width: 575.98px) {
  .footer__license {
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
  }
}

@media (max-width: 991.98px) {
  .footer__logo {
    display: none;
  }
}

.footer__payments {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
}

@media (max-width: 767.98px) {
  .footer__payments {
    justify-content: center;
    margin-bottom: 20px;
  }
}
@media (max-width: 575.98px) {
  .footer__payments {
    gap: 20px;
  }
}
.footer__payments-title {
  font-size: 14px;
  line-height: 14px;
  margin-right: 30px;
}

@media (max-width: 575.98px) {
  .footer__payments-title {
    font-size: 12px;
    line-height: 12px;
    margin-right: 10px;
  }
}

.footer__payments-img {
  display: block;
}

.footer__copy {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #9a9a9a;
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  .footer__copy {
    text-align: center;
    margin-bottom: 20px;
  }
}
@media (max-width: 575.98px) {
  .footer__copy {
    margin-bottom: 15px;
  }
}

/*--------------------------------------------------------------
# end footer style
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# header-sidebar style
--------------------------------------------------------------*/

header {
  position: relative;
  z-index: 999;
}
.header-mobile {
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: #121933;
  display: none;
}
@media (max-width: 1300px) {
  .header-mobile {
    display: flex;
  }
}
.header-mobile_logo {
  display: inline-block;
}
.header-sidebar {
  background: #121933;
  width: 276px;
  height: 100vh;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  padding: 50px 21px;
  transition: 0.3s all;
}

@media (max-width: 1300px) {
  .header-sidebar {
    left: -276px;
  }
  .header-sidebar.active {
    left: 0;
    transition: 0.3s all;
  }
}

.header-sidebar_logo {
  display: inline-block;
  margin: 0 auto 35px;
}
.header-sidebar_nav {
  margin-bottom: 40px;
}

.header-sidebar_account {
  text-align: center;
  margin-bottom: 30px;
}
.header-sidebar_account-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 auto 20px;
  overflow: hidden;
}
.header-sidebar_account-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header-sidebar_account-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  color: #ffffff;
}
.header-sidebar_account-down {
  background-image: url(@/Images/cards_images/account-down.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 11px;
  height: 7px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 13px;
}
.btn-header-sidebar {
  display: none;
  width: 18px;
  height: 18px;
  margin-right: 20px;
  background-image: url(@/Images/cards_images/menu.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  background-color: transparent;
  position: relative;
  filter: invert(1);
}

.btn-header-sidebar::before,
.btn-header-sidebar::after {
  content: "";
  position: absolute;
  top: 9px;
  width: 18px;
  height: 2px;
  background-color: #000;
  left: 0;
  opacity: 0;
  transition: 0.3s all;
}

.btn-header-sidebar::before {
  transform: rotate(45deg);
}

.btn-header-sidebar::after {
  transform: rotate(135deg);
}

.btn-header-sidebar.is-active {
  background-image: none;
  transition: 0.3s all;
}

.btn-header-sidebar.is-active::before,
.btn-header-sidebar.is-active::after {
  opacity: 1;
  transition: 0.3s all;
}

@media (max-width: 1300px) {
  .btn-header-sidebar {
    display: block;
  }
}

.header-sidebar_li {
  text-align: center;
  line-height: 1;
}

.header-sidebar_li a {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  gap: 10px;
  padding: 16px 0;
  width: 100%;
  background: #222e52;
  border-radius: 7px;
}

.header-sidebar_li:not(:last-child) {
  margin-bottom: 20px;
}

.header-sidebar_li img {
  width: 15px;
  height: auto;
}

.header-sidebar_li span {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  text-align: center;
}

.header-sidebar_li--active a {
  background: #5ca1ff;
}

.header-sidebar_currency {
  background: #ef382a;
  box-shadow: 0px 4px 74px rgba(0, 0, 0, 0.44);
  border-radius: 21px;
  padding: 18px 15px;
  margin-bottom: 40px;
}
.header-sidebar_currency-label {
  text-align: left;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  margin-bottom: 10px;
}
.header-sidebar_currency-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.7px solid rgba(255, 255, 255, 0.8);
  border-radius: 6px;
  padding: 7px 9px;
  margin-bottom: 20px;
}
.header-sidebar_currency-item input {
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  opacity: 0.9;
  background: transparent;
  border: none;
  width: 70%;
}
.header-sidebar_currency-item input::placeholder {
  color: #ffffff;
  opacity: 0.9;
}
.header-sidebar_currency-item select {
  background: transparent;
  border: none;
  width: 30%;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.9;
}
.header-sidebar_currency-btn {
  margin: 0 auto 20px;
  background: #ffffff;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #ef382a;
  padding: 12px 35px;
  border-radius: 6px;
  display: inline-flex;
}
.header-sidebar_currency-desc {
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  color: #ffffff;
  text-align: center;
  width: 100%;
}
.header-sidebar_rating {
  display: flex;
  justify-content: space-between;
}
.header-sidebar_rating-label {
  position: relative;
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  opacity: 0.9;
  text-align: left;
  margin-bottom: 5px;
}
.header-sidebar_rating-label:after {
  content: "";
  position: relative;
  display: inline-block;
  width: 5px;
  height: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 5px;
}
.header-sidebar_rating-label--rising:after {
  background-image: url(@/Images/cards_images/rising.svg);
}
.header-sidebar_rating-label--falling:after {
  background-image: url(@/Images/cards_images/falling.svg);
}
.header-sidebar_rating-value {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  opacity: 0.9;
  text-align: left;
}
/*--------------------------------------------------------------
# end header-sidebar style
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Transaction History style
--------------------------------------------------------------*/

.blue-bg {
  background: #f3f7fd;
  padding-left: 276px;
  padding-right: 0px;
  position: relative;
}

.background {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  display: none;
}

.background.active {
  display: block;
}

@media (max-width: 1300px) {
  .blue-bg {
    padding-left: 0;
    padding-right: 0;
  }
}

.main-content {
  padding-bottom: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.main-content_top {
  width: 100%;
  position: relative;
}
.main-content_top-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

@media (max-width: 767.98px) {
  .main-content_top-line {
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
  }
}

.main-content_left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 40px;
}

.main-content_btn {
  display: flex;
  border-radius: 6px;
  border: 1px solid #c3ced7;
  overflow: hidden;
}

@media (max-width: 575.98px) {
  .main-content_left {
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
  }
  .main-content_btn {
    width: 100%;
  }
  .main-content_btn a {
    flex: 1;
    text-align: center;
  }
}
.main-content_btn * {
  border: none;
  border-radius: 0;
}
.main-content_btn *:not(:last-child) {
  border-right: 1px solid #c3ced7;
}
.main-content_content {
  width: calc(100% - 304px);
  flex: 1;
}
@media (max-width: 1300px) {
  .main-content_content {
    width: 100%;
    max-width: 100%;
  }
}
.main-content_sidebar {
  width: 280px;
  flex: none;
}
.form-orders {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  gap: 20px;
  background: #ffffff;
  border-radius: 21px;
  padding: 15px 20px;
}

@media (max-width: 767.98px) {
  .form-orders {
    flex-wrap: wrap;
    padding: 15px;
    margin-bottom: 0;
  }
}
.main-content_content .form-orders {
  margin-bottom: 40px;
}
.form-orders_left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 27px;
}

@media (max-width: 575.98px) {
  .form-orders_left {
    flex-wrap: wrap;
    gap: 12px 15px;
  }
}

.form-orders_right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

@media (max-width: 767.98px) {
  .form-orders_right {
    width: 100%;
    justify-content: space-between;
    order: -1;
  }
}

.form-orders_submit {
  background-image: url(@/Images/cards_images/search.svg);
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 33px center;
  border-radius: 6px;
  border: none;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  padding: 11px 31px 12px 51px;
}
.form-orders_item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
@media (max-width: 575.98px) {
  .form-orders_item {
    min-width: calc(50% - 10px);
  }
}

.form-orders_item label {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #9a9a9a;
  margin-bottom: 0;
}

@media (max-width: 575.98px) {
  .form-orders_item label {
    margin-bottom: 4px;
  }
}

.form-orders_item select {
  display: block;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #121933;
  background-color: transparent;
  border: none;
  padding-right: 25px;
  background-image: url(@/Images/cards_images/drop-grey-sm.svg);
  background-position: 93% center;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media (max-width: 575.98px) {
  .form-orders_item select {
    font-size: 11px;
    padding-right: 18px;
  }
}

.form-orders_clear {
  background: #ffffff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.form-orders_clear img {
  width: 18px;
}

.table-orders {
  background: #ffffff;
  padding: 0 30px;
  height: auto;
  /* max-height: calc(100vh - 270px); */
  border-radius: 21px;
}

@media (max-width: 1300px) {
  .table-orders {
    padding: 0 20px;
    overflow: visible;
    max-height: 100%;
  }
}
.table-orders--auto {
  max-height: none;
  overflow: visible;
}
.table-orders_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 30px;
}

@media (max-width: 1300px) {
  .table-orders_header {
    padding: 20px 0;
  }
}

@media (max-width: 575.98px) {
  .table-orders_header {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  .table-orders_header button {
    width: 100%;
  }
}

.table-orders_title {
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  color: #0d1118;
}

@media (max-width: 1300px) {
  .table-orders_title {
    font-size: 14px;
  }
}

.table-orders_title span {
  font-weight: 600;
}

.table-orders_date {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #9a9a9a;
}

.table-orders_item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
  padding: 20px;
  background: #f4f4f4;
  border-radius: 16px;
  margin: 15px 0;
}

@media (max-width: 1300px) {
  .table-orders_item {
    min-width: 815px;
    width: 100%;
  }
}

.table-orders_item-number {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #9a9a9a;
  width: 5%;
}

.table-orders_item-logo {
  font-size: 12px;
  line-height: 107%;
  font-weight: 400;
  color: #000000;
  display: flex;
  align-items: center;
  width: 11.5%;
  position: relative;
}

@media (max-width: 1300px) {
  .table-orders_item-logo {
    font-size: 14px;
  }
}

@media (max-width: 991.98px) {
  .table-orders_item-logo {
    width: 8.5%;
  }
}

.table-orders_item-logo img {
  margin-right: 5px;
  width: 20px;
  flex: none;
}

@media (max-width: 1300px) {
  .table-orders_item-logo img {
    width: 18px;
  }
}

.nologo {
  display: inline-block;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  flex: none;
}

@media (max-width: 1300px) {
  .nologo {
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 991.98px) {
  .nologo {
    display: none;
  }
}

.table-orders_item-date {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #9a9a9a;
  text-align: left;
  width: 12%;
}

@media (max-width: 991.98px) {
  .table-orders_item-date {
    width: 9%;
    text-align: left;
  }
}

.table-orders_item-code {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: #0d1118;
  width: 30%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media (max-width: 991.98px) {
  .table-orders_item-code {
    width: 33%;
  }
}

.table-orders_item-code::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.table-orders_item-from {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #9a9a9a;
  width: 4.5%;
}

.table-orders_item-from-card {
  width: 11%;
  padding-left: 0px;
  font-weight: 400;
  font-size: 12px;
  line-height: 11px;
  color: #9a9a9a;
}

@media (max-width: 991.98px) {
  .table-orders_item-from-card {
    padding-left: 0;
  }
}

.table-orders_item-to {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #0d1118;
  width: 15%;
}
.table-orders_item-to--short {
  width: 10%;
}
.table-orders_item-to span {
  color: #9a9a9a;
  display: block;
}

.table-orders_item-to-card {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #0d1118;
  width: 10%;
}

.table-orders_item-to-card span {
  font-weight: 400;
  font-size: 12px;
  line-height: 96.5%;
  color: #2bb867;
}

.table-orders_item-status {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #0d1118;
  width: auto;
  flex: 1;
}

.table-orders_item-status--received {
  color: #fda24c;
}

.table-orders_item-status--fulfilled {
  color: #b47cc0;
}

.table-orders_item-status--charged {
  color: #53bd73;
}

.table-orders_item-status--executed {
  color: #2bb867;
}
.table-orders_item-status--error {
  color: #ef382a;
}
.table-orders_item-status--pending {
  color: #5ca1ff;
}
.table-orders_item-status--done {
  color: #0bde20;
}
.table-orders_item-bank {
  text-align: left;
  width: 15%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #9a9a9a;
}

.table-orders_item-view {
  width: 20px;
  position: relative;
}
.table-orders_item-title {
  background: transparent;
  border-top: 1px solid #f0f5f9;
  padding-bottom: 0;
  margin: 0;
  border-radius: 0;
}
.table-orders_item-title * {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 12px !important;
  color: #9a9a9a !important;
  text-align: left !important;
}
.table-orders_item--black {
  color: #0d1118;
}
.button-view-hover {
  width: 15px;
  height: 15px;
  margin: 0 auto;
  background-image: url(@/Images/cards_images/view.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.view-hover {
  position: absolute;
  background: #ffffff;
  box-shadow: 16px 13px 35px rgba(5, 9, 78, 0.2);
  border-radius: 21px;
  width: 300px;
  right: 0;
  top: calc(100% + 15px);
  z-index: 9990;
  display: none;
}

.active .view-hover {
  display: block;
}

@media (max-width: 575.98px) {
  .active .view-hover {
    position: fixed;
    top: 150px;
    left: calc(50% - 150px);
  }
}

.view-hover--logo {
  right: auto;
  left: 0;
}

.view-hover_close {
  z-index: 999;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 15px;
  height: 15px;
  background-image: url(@/Images/cards_images/close.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
}

.view-hover_tilte {
  font-weight: 400;
  font-size: 12px;
  line-height: 107%;
  color: #000000;
  padding: 25px 20px 20px;
  border-bottom: 1px solid #f0f5f9;
  position: relative;
  z-index: 1;
}

.view-hover_body {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}

.view-hover_item {
  width: 100%;
  margin-bottom: 20px;
}

.view-hover_item:last-child {
  margin-bottom: 0;
}

.view-hover_item--short {
  width: 50%;
}

.view-hover_lavel {
  font-weight: 400;
  font-size: 12px;
  line-height: 96.5%;
  color: #9a9a9a;
  margin-bottom: 10px;
}

.view-hover_value {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #0d1118;
  word-wrap: break-word;
}

.view-hover_value span.grey {
  color: #777e8a;
}

.view-hover_value span.red {
  color: #f30909;
}

.view-hover_value span.green {
  color: #2bb867;
}

.btn-load-more {
  display: inline-block;
  margin: 0 auto;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #0d1118;
}

.btn-load-more img {
  display: inline-block;
  width: 22px;
  vertical-align: sub;
  margin-right: 4px;
  transform: rotate(-45deg);
  margin-bottom: -3px;
}

.load-more-center {
  user-select: none;
  text-align: center;
  margin: 15px 0 35px;
  cursor: pointer;
}

@media (max-width: 575.98px) {
  .load-more-center {
    margin: 30px 0;
  }
}

/*--------------------------------------------------------------
# end Transaction History style
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# account-sidebar  style
--------------------------------------------------------------*/

.account-sidebar {
  position: sticky;
  top: 0;
  right: 0;
  width: 280px;
  height: 100vh;
  z-index: 3000;
  overflow: auto;
}

@media (max-width: 1300px) {
  .account-sidebar {
    right: -320px;
    width: 320px;
    transition: 0.3s all;
    background: #fff;
    position: fixed;
  }
  .account-sidebar.active {
    right: 0;
    transition: 0.3s all;
  }
}

@media (max-width: 575.98px) {
  .account-sidebar {
    width: 270px;
    right: -270px;
    /* padding: 28px 0 30px 18px; */
  }
}

.btn-account-sidebar {
  display: none;
  width: 40px;
  height: 40px;
  position: fixed;
  right: 0;
  top: 180px;
  background: #ffffff;
  background-image: url(@/Images/cards_images/btnas.svg);
  background-size: cover;
  background-repeat: no-repeat;
  transition: 0.3s all;
  box-shadow: 0 3px 10px 0 #00000020;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.btn-account-sidebar .is-active {
  background-image: url(@/Images/cards_images/btnash.svg);
  right: 320px;
  transition: 0.3s all;
}

@media (max-width: 1300px) {
  .btn-account-sidebar {
    display: block;
  }
}

@media (max-width: 1062px) {
  .btn-account-sidebar.is-active {
    right: 320px;
  }
}

@media (max-width: 575.98px) {
  .btn-account-sidebar.is-active {
    right: 270px;
    box-shadow: none;
  }
}

.account-sidebar_header {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

@media (max-width: 575.98px) {
  .account-sidebar_header {
    margin-bottom: 30px;
  }
}

.account-sidebar_image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

@media (max-width: 575.98px) {
  .account-sidebar_image {
    width: 35px;
    height: 35px;
  }
}

.account-sidebar_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.account-sidebar_name {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 3px;
}

@media (max-width: 575.98px) {
  .account-sidebar_name {
    font-size: 12px;
    margin-bottom: 0;
  }
}

.account-sidebar_status {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #777e8a;
}

@media (max-width: 575.98px) {
  .account-sidebar_status {
    font-size: 11px;
  }
}

.account-sidebar_verified {
  position: relative;
}

.account-sidebar_verified:after {
  content: "";
  position: absolute;
  top: 1px;
  left: calc(100% + 10px);
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-image: url(@/Images/cards_images/verif.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-width: 575.98px) {
  .account-sidebar_verified:after {
    width: 14px;
    height: 14px;
    left: calc(100% + 5px);
    top: 2px;
  }
}

.account-sidebar_btn {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  background: #ffffff;
  border-radius: 21px;
  padding: 20px;
}

@media (max-width: 575.98px) {
  .account-sidebar_btn {
    margin-bottom: 30px;
  }
}

.account-sidebar_btn .btn-sq {
  padding: 0 20px;
  white-space: nowrap;
}

.account-sidebar_cards {
  background: #ffffff;
  border-radius: 21px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  max-height: 600px;
  overflow: auto;
}

@media (max-width: 575.98px) {
  .account-sidebar_cards {
    padding-right: 15px;
    margin-bottom: 30px;
    padding-top: 30px;
  }
}

.account-sidebar_cards-title {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #9a9a9a;
}

.account-sidebar_cards-add {
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #eaeef1;
  background-color: #ffffff;
  font-weight: 700;
  cursor: pointer;
}

.account-sidebar_cards-add:hover {
  opacity: 0.8;
  text-decoration: none;
}

.account-sidebar_cards-add img {
  width: 8px;
}

.account-sidebar_cards-item {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #f0f5f9;
  padding: 10px 0;
}

.account-sidebar_cards-item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.account-sidebar_cards-name,
.account-sidebar_cards-value {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #011928;
}

.account-sidebar_cards-name img {
  width: 25px;
  margin-right: 7px;
  display: inline;
  vertical-align: middle;
}

.account-sidebar_cards-name span,
.account-sidebar_cards-value span {
  display: block;
  margin-top: 5px;
  color: #9a9a9a;
}

.account-sidebar_cards-value {
  text-align: right;
}

.account-sidebar_cards-value strong {
  line-height: 25px;
}

.account-sidebar_footer {
  margin: 20px 0;
  padding: 20px;
  border-radius: 21px;
  background: #ffffff;
  font-size: 14px !important;
}

@media (max-width: 575.98px) {
  .account-sidebar_footer {
    margin: 30px 0;
    padding-top: 30px;
  }
}

.account-sidebar_footer:last-child {
  margin-bottom: 0;
}

.account-sidebar_footer-title {
  margin-bottom: 10px;
  font-weight: 400;
  line-height: 128.52%;
  color: #000000;
}

@media (max-width: 575.98px) {
  .account-sidebar_footer-title {
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 15px;
  }
}

.account-sidebar_footer-desc {
  font-weight: 400;
  line-height: 159%;
  color: #9a9a9a;
}

@media (max-width: 575.98px) {
  .account-sidebar_footer-desc {
    font-size: 11px;
  }
}

.account-sidebar_footer-desc span {
  color: #000000;
}

.account-sidebar_footer-btn {
  margin-top: 20px;
  padding-left: 29px;
  padding-right: 29px;
}
.account-sidebar_footer-line {
  margin: 20px 0;
  width: 100%;
  height: 1px;
  background: #f0f5f9;
}
.table-orders_header-filter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

@media (max-width: 575.98px) {
  .table-orders_header-filter {
    justify-content: flex-start;
    width: 100%;
    margin-top: 20px;
  }
}

.btn-filter {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #5ca1ff;
  cursor: pointer;
}

.btn-filter svg {
  width: 20px;
  margin-right: 5px;
}

.btn-search {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #9a9a9a;
}

.btn-search svg {
  margin-right: 5px;
  width: 12px;
}

.block-filter {
  position: relative;
}

.filter-hover {
  display: none;
  position: absolute;
  top: calc(100% + 18px);
  right: 0;
  z-index: 999;
  background: #ffffff;
  box-shadow: 16px 13px 35px rgba(5, 9, 78, 0.2);
  width: 232px;
  border-radius: 21px;
}

@media (max-width: 575.98px) {
  .filter-hover {
    right: auto;
    left: 0;
  }
}

.filter-hover.active {
  display: block;
}

.filter-hover_title {
  padding: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 107%;
  color: #000000;
  border-bottom: 1px solid #f0f5f9;
}

.filter-hover_block {
  padding: 20px;
}

.filter-hover_block label {
  padding-left: 21px;
  position: relative;
  display: block;
  line-height: 1;
  margin-bottom: 14px;
}

.filter-hover_block label:before {
  content: "";
  position: absolute;
  top: 1px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #777e8a;
  border-radius: 4px;
}

.filter-hover_block input:checked + label:before {
  background-image: url(@/Images/cards_images/verif.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #3f48cc;
}

.filter-hover_block input {
  position: absolute;
  opacity: 0;
  z-index: -999;
}

.filter-hover_block label span {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #0d1118;
}

.filter-hover_btn {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.filter-hover_btn .btn-white {
  padding: 0 16px;
  flex: none;
}

.filter-hover_btn .btn-blue {
  flex: 1;
  padding: 0;
  text-align: center;
}

.table-orders_cards-filter {
  border-top: 1px solid #f0f5f9;
  padding: 20px 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1300px) {
  .table-orders_cards-filter {
    min-width: 815px;
  }
}

.table-orders_cards-filter div {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #9a9a9a;
}

.table-orders_cards-filter span {
  position: relative;
  padding-right: 15px;
}

.table-orders_cards-filter span.active {
  color: #5ca1ff;
}

.table-orders_cards-filter span:after {
  content: "";
  width: 10px;
  height: 6px;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  top: 4px;
}

.table-orders_cards-filter span.active:after {
  background-image: url(@/Images/cards_images/active.svg);
}

.table-orders_cards-filter_label {
  margin-right: 5px;
  display: inline-block;
  vertical-align: sub;
}

.table-orders_cards-filter_label label {
  width: 15px;
  height: 15px;
  border: 1px solid #9a9a9a;
  border-radius: 4px;
  margin: 0;
  display: block;
  cursor: pointer;
}

.table-orders_cards-filter_label input:checked + label {
  background-image: url(@/Images/cards_images/verif.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #3f48cc;
}

.table-orders_cards-filter_label input {
  position: absolute;
  opacity: 0;
  z-index: -999;
}

.table-orders_body {
  padding-bottom: 40px;
}

@media (max-width: 1300px) {
  .table-orders_body {
    overflow: auto;
    max-height: 465px;
  }
}

@media (max-width: 575.98px) {
  .table-orders_body {
    width: calc(100%);
    padding-right: 20px;
  }
}

.table-orders--auto .table-orders_body {
  padding-top: 10px;
  padding-bottom: 15px;
}
@media (max-width: 1300px) {
  .table-orders--auto .table-orders_body {
    max-height: none;
  }
}
.table-orders--auto .table-orders_item-title {
  border-top: none;
}
.table-orders_cards-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 20px;
  margin: 15px 0;
  background: #f4f4f4;
  border-radius: 16px;
}

@media (max-width: 1300px) {
  .table-orders_cards-item {
    min-width: 815px;
    padding: 20px;
  }
}

.table-orders_cards-item div {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #9a9a9a;
}

.table-orders_cards-item .table-orders_cards-filter_account {
  color: #5ca1ff;
}

.table-orders_cards-item .table-orders_cards-filter_statust {
  color: #53bd73;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.table-orders_cards-filter_title {
  width: 20%;
}
@media (max-width: 1400px) {
  .table-orders_cards-filter_title {
    width: 25%;
  }
}
.table-orders_cards-filter_name {
  width: 12%;
}
@media (max-width: 1400px) {
  .table-orders_cards-filter_name {
    width: 14%;
  }
}

.table-orders_cards-filter_nickname {
  width: 13%;
}
@media (max-width: 1400px) {
  .table-orders_cards-filter_nickname {
    width: 15%;
  }
}

.table-orders_cards-filter_date {
  width: 10%;
}
@media (max-width: 1400px) {
  .table-orders_cards-filter_date {
    width: 12%;
  }
}

.table-orders_cards-filter_type {
  width: 12%;
}
@media (max-width: 1400px) {
  .table-orders_cards-filter_type {
    width: 12%;
  }
}

.table-orders_cards-filter_account {
  width: 8%;
  background: none !important;
}
.table-orders_cards-filter_account span::after {
  background-image: none !important;
}
@media (max-width: 1400px) {
  .table-orders_cards-filter_account {
    width: 9%;
  }
}

.table-orders_cards-filter_statust {
  width: 11%;
}
@media (max-width: 1400px) {
  .table-orders_cards-filter_statust {
    width: 11%;
  }
}

.main-content_one-card {
  background: #ffffff;
  padding: 20px;
  border-radius: 21px;
  margin-bottom: 40px;
}

@media (max-width: 1300px) {
  .main-content_one-card {
    padding: 20px;
    margin-bottom: 20px;
  }
}

.main-content_one-card_title {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #0d1118;
}

.main-content_one-card_info {
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  color: #777e8a;
}

.main-content_one-card_ul {
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
}

.main-content_one-card_ul li {
  position: relative;
  padding: 0 0 5px;
  border-bottom: 2px solid transparent;
}

.main-content_one-card_ul li a {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #777e8a;
}

.main-content_one-card_ul li:hover a {
  text-decoration: none;
  color: #5ca1ff;
}

.main-content_one-card_ul li.active {
  border-color: #5ca1ff;
}

.main-content_one-card_ul li.active a {
  color: #5ca1ff;
}

.main-content_list-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin: 30px 0;
}
@media (max-width: 767.98px) {
  .main-content_list-btn {
    flex-wrap: wrap;
    gap: 10px;
  }
}
@media (max-width: 575.98px) {
  .main-content_list-btn {
    margin: 20px 0;
  }
}

.btn-modal {
  padding: 7px 15px;
  border: none;
  background: #f3f7fd;
  border-radius: 26px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #777e8a;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-modal img {
  width: 16px;
  margin-right: 5px;
}

.btn-modal--red {
  color: #f30909;
}

.block-one-card {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
}

@media (max-width: 991.98px) {
  .block-one-card {
    gap: 20px;
  }
}

.block-one-card_visual {
  width: 285px;
  flex: none;
  background: #5ca1ff;
  box-shadow: 16px 13px 35px rgba(5, 9, 78, 0.2);
  border-radius: 16px;
  padding: 15px 20px 20px;
}

@media (max-width: 575.98px) {
  .block-one-card_visual {
    width: 100%;
    max-width: 285px;
  }
}

.block-one-card_visual_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 43px;
}

.block-one-card_visual_view {
  width: 20px;
  height: 20px;
  display: block;
  background-image: url(@/Images/cards_images/view-w.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.no-view {
  background-image: url(@/Images/cards_images/no-view.svg) !important;
}
.block-one-card_visual_logo {
  width: 53px;
  height: auto;
}

.block-one-card_visual_number-card {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.29em;
  color: #ffffff;
  margin-bottom: 35px;
}

.block-one-card_visual_label {
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  color: #ffffff;
}

.block-one-card_visual_value {
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  color: #ffffff;
  margin-bottom: 2px;
}

.block-one-card_visual_bottom {
  display: flex;
  align-items: flex-start;
  gap: 30px;
}

.button-view-details {
  width: 18px;
  height: 18px;
  background-image: url(@/Images/cards_images/view.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  margin-left: 7px;
}
.no-view2 {
  background-image: url(@/Images/cards_images/no-view2.svg) !important;
}

.block-one-card_info {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
  width: 100%;
}

@media (max-width: 991.98px) {
  .block-one-card_info {
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .block-one-card_info {
    flex-wrap: wrap;
    gap: 20px;
  }
}
@media (max-width: 575.98px) {
  .block-one-card_info {
    gap: 30px;
  }
}

.block-one-card_details {
  flex: 1;
  max-width: 35%;
}
@media (max-width: 767.98px) {
  .block-one-card_details {
    min-width: 230px;
  }
}
@media (max-width: 575.98px) {
  .block-one-card_details {
    min-width: 100%;
  }
}

.block-one-card_info-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 107%;
  color: #000000;
  border-bottom: 1px solid #f0f5f9;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.block-one-card_info-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 107%;
  color: #9a9a9a;
}

.block-one-card_info-value {
  font-weight: 500;
  font-size: 14px;
  line-height: 107%;
  text-align: right;
  color: #000000;
  display: flex;
  align-items: center;
}

.block-one-card_info-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.block-one-card_info-item:not(:last-child) {
  margin-bottom: 15px;
}

.btn-change {
  width: 10px;
  height: 10px;
  border: none;
  background-image: url(@/Images/cards_images/pencil.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}
.btn-limit-save {
  color: #380bd7;
  cursor: pointer;
  display: block;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
  border-radius: 4px;
  background-color: #380bd744;
}
.btn-limit-save:hover {
  box-shadow: 0 0 0 1px #380bd7;
}

.block-card-limits {
  gap: 30px 20px;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 575.98px) {
  .block-card-limits {
    gap: 15px;
  }
}
.block-card-limits_one {
  width: calc(50% - 10px);
  background: #ffffff;
  border-radius: 21px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

@media (max-width: 767.98px) {
  .block-card-limits_one {
    width: 100% !important;
  }
}

.block-card-limits_one-top {
  width: 100%;
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.block-card-limits_one-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 107%;
  color: #777e8a;
}

.block-card-limits_one-item {
  width: calc(50% - 10px);
}
@media (max-width: 767.98px) {
  .block-card-limits_one-item {
    width: 100% !important;
    margin: 8px 0;
  }
}

.block-card-limits_one-value {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 107%;
  color: #000000;
  max-height: 27px;
  height: 20px;
}
.block-card-limits_one input {
  box-sizing: border-box;
  width: 6ch;
  color: inherit;
}
.disabled .card-limit-progressbar_indicator {
  background-color: silver !important;
}
.card-limit-progressbar {
  position: relative;
  padding-top: 4px;
  margin-bottom: 10px;
}
.card-limit-progressbar .fail-limit {
  background-color: red !important;
}
.card-limit-progressbar_indicator {
  position: absolute;
  height: 4px;
  background-color: #380bd7;
  z-index: 1;
}
.card-limit-progressbar_bg {
  position: absolute;
  left: 0;
  top: 4px;
  width: 100%;
  height: 4px;
  background-color: #e0e4ea;
  z-index: 0;
}
.block-card-limits_one-value input {
  border: none;
  font-weight: 500;
}

.disabled .block-card-limits_one-value {
  color: #777e8a;
  border-color: #777e8a;
}

.disabled .btn-change {
  opacity: 0.5;
}

.block-card-limits_one-value span {
  margin-right: 5px;
}

.block-card-limits_one-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 13px;
  line-height: 107%;
  color: #777e8a;
}

.checkbox-ios {
  display: inline-block;
  height: 15px;
  line-height: 15px;
  margin-bottom: 0;
  position: relative;
  vertical-align: middle;
  user-select: none;
}

.checkbox-ios .checkbox-ios-switch {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 28px;
  height: 15px;
  border-radius: 25%/50%;
  vertical-align: top;
  background: #777e8a;
  transition: 0.2s;
  border: none;
}

.checkbox-ios .checkbox-ios-switch:before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  display: inline-block;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #fff;
  transition: 0.15s;
}

.checkbox-ios input[type="checkbox"] {
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox-ios input[type="checkbox"]:not(:disabled):active + .checkbox-ios-switch:before {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

.checkbox-ios input[type="checkbox"]:checked + .checkbox-ios-switch {
  background: #380bd7;
}

.checkbox-ios input[type="checkbox"]:checked + .checkbox-ios-switch:before {
  transform: translateX(13px);
}

.checkbox-ios input[type="checkbox"]:not(:disabled) + .checkbox-ios-switch {
  cursor: pointer;
  border-color: rgba(0, 0, 0, 0.3);
}

.checkbox-ios.focused .checkbox-ios-switch:before {
  box-shadow: inset 0px 0px 4px #ff5623;
}

.modal-body form label {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #777e8a;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.modal-body form input:not([type="submit"]) {
  font-weight: 400;
  margin: 0;
  width: 100%;
  background: #ffffff;
  border: 0.7px solid #212943;
  border-radius: 6px;
  height: 46px;
  padding: 0 15px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #212944;
}

.modal-body form input[type="submit"] {
  text-transform: uppercase;
  min-width: 120px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}

.modal-body form .iti {
  margin-bottom: 20px;
  width: 100%;
}

.modal-body form .iti input {
  margin-left: 70px;
  width: calc(100% - 70px);
}

.modal-body form .iti__selected-flag {
  background: #ffffff;
  border: 0.7px solid #212943;
  border-radius: 6px;
  width: 55px;
  padding: 0 10px;
}

/*--------------------------------------------------------------
# end account-sidebar style
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# buy step 1
--------------------------------------------------------------*/

.buy-steps {
  position: relative;
  z-index: 0;
  background: #f5faff;
}

.buy-steps__wrapper {
  padding: 20px 0 90px;
  position: relative;
  z-index: 1;
  color: #fff;
}

@media (max-width: 575.98px) {
  .buy-steps__wrapper {
    padding-bottom: 80px;
  }
}

.buy-steps__logo {
  display: block;
  margin: 0 auto 40px;
  width: 97px;
}
@media (max-width: 991.98px) {
  .buy-steps__logo {
    margin-bottom: 20px;
  }
}
@media (max-width: 575.98px) {
  .buy-steps__logo {
    margin-bottom: 15px;
    width: 75px;
  }
}
.buy-steps__h2 {
  margin-bottom: 40px;
  color: #212842;
  font-weight: 500;
  font-size: 40px;
  line-height: 1.65;
  text-align: center;
  text-transform: uppercase;
}
@media (max-width: 991.98px) {
  .buy-steps__h2 {
    font-size: 30px;
    margin-bottom: 25px;
  }
}
@media (max-width: 575.98px) {
  .buy-steps__h2 {
    font-size: 26px;
    margin-bottom: 20px;
  }
}
.buy-steps__line {
  max-width: 640px;
  margin: 0 auto 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

@media (max-width: 991.98px) {
  .buy-steps__line {
    max-width: 545px;
  }
}

@media (max-width: 767.98px) {
  .buy-steps__line {
    max-width: 305px;
  }
}
@media (max-width: 575.98px) {
  .buy-steps__line {
    max-width: 260px;
  }
}

.buy-steps__line-item {
  padding-bottom: 40px;
  margin-right: 2px;
  position: relative;
  z-index: 0;
  width: 100%;
}

.buy-steps__line-item > span {
  position: absolute;
  z-index: 0;
  font-size: 18px;
  line-height: 18px;
  white-space: nowrap;
  color: #bebebe;
}

.buy-steps__line-item.done > span,
.buy-steps__line-item.active > span,
.buy-steps__line-item.next > span {
  color: #212842;
}

@media (max-width: 991.98px) {
  .buy-steps__line-item > span {
    font-size: 14px;
    line-height: 14px;
  }
}

@media (max-width: 767.98px) {
  .buy-steps__line-item > span {
    font-size: 10px;
    line-height: 10px;
    white-space: pre-wrap;
    text-align: left;
    max-width: 60px;
  }
}
@media (max-width: 575.98px) {
  .buy-steps__line-item > span {
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    max-width: 70px;
  }
}
.buy-steps__line-item:first-child > span {
  left: -85px;
}

.buy-steps__line-item:nth-child(2) > span {
  left: -5px;
}

.buy-steps__line-item:nth-child(3) > span {
  left: 60px;
}

.buy-steps__line-item:last-child > span {
  right: -85px;
}

@media (max-width: 991.98px) {
  .buy-steps__line-item:first-child > span {
    left: -56px;
  }
  .buy-steps__line-item:nth-child(2) > span {
    left: -6px;
  }
  .buy-steps__line-item:nth-child(3) > span {
    left: 28px;
  }
  .buy-steps__line-item:last-child > span {
    right: -62px;
  }
}

@media (max-width: 767.98px) {
  .buy-steps__line-item:first-child > span {
    left: -10px;
  }
  .buy-steps__line-item:nth-child(2) > span {
    left: 14px;
  }
  .buy-steps__line-item:nth-child(3) > span {
    left: 34px;
  }
  .buy-steps__line-item:last-child > span {
    right: -35px;
  }
}
@media (max-width: 575.98px) {
  .buy-steps__line-item:first-child > span {
    left: -35px;
  }
  .buy-steps__line-item:nth-child(2) > span {
    left: -10px;
  }
  .buy-steps__line-item:nth-child(3) > span {
    left: 24px;
  }
  .buy-steps__line-item:last-child > span {
    right: -30px;
  }
}
.buy-steps__line-item::after {
  content: "";
  position: absolute;
  z-index: 0;
  width: 100%;
  border-bottom: 1px dashed #bebebe;
  bottom: -17px;
}
.buy-steps__line-item.done::after {
  border-bottom: 1px solid #ef382a;
}
.buy-steps__line-item.active::after {
  border-bottom: 1px solid #ef382a;
}
.buy-steps__line-item:first-child::after {
  left: 13px;
  width: 225px;
}

.buy-steps__line-item:nth-child(2)::after {
  left: 80px;
  width: 210px;
}

.buy-steps__line-item:nth-child(3)::after {
  left: 135px;
  width: 200px;
}

@media (max-width: 991.98px) {
  .buy-steps__line-item:first-child::after {
    left: 11px;
    width: 175px;
  }
  .buy-steps__line-item:nth-child(2)::after {
    left: 62px;
    width: 175px;
  }
  .buy-steps__line-item:nth-child(3)::after {
    left: 98px;
    width: 175px;
  }
}

@media (max-width: 767.98px) {
  .buy-steps__line-item:first-child::after {
    left: 10px;
    width: 95px;
  }
  .buy-steps__line-item:nth-child(2)::after {
    left: 30px;
    width: 95px;
  }
  .buy-steps__line-item:nth-child(3)::after {
    left: 50px;
    width: 95px;
  }
}
@media (max-width: 575.98px) {
  .buy-steps__line-item:first-child::after {
    left: 0px;
    width: 85px;
  }
  .buy-steps__line-item:nth-child(2)::after {
    left: 25px;
    width: 85px;
  }
  .buy-steps__line-item:nth-child(3)::after {
    left: 50px;
    width: 85px;
  }
}
.buy-steps__line-item:last-child::after {
  display: none;
}

.buy-steps__line-item::before {
  content: "";
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: 3px solid #bebebe;
  background: #fff;
  position: absolute;
  z-index: 1;
  bottom: -23px;
}

.buy-steps__line-item.next::before {
  border-color: #212842;
}
.buy-steps__line-item.active::before {
  border-color: #ef2a2a;
}
.buy-steps__line-item.done::before {
  border-color: #ef2a2a;
  background-color: #ef2a2a;
  background-image: url("Images/cards_images/done-white.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}

.buy-steps__line-item:first-child::before {
  left: -4px;
}

.buy-steps__line-item:nth-child(2)::before {
  left: 68px;
}

.buy-steps__line-item:nth-child(3)::before {
  left: 123px;
}

.buy-steps__line-item:last-child::before {
  right: -25px;
}

@media (max-width: 991.98px) {
  .buy-steps__line-item:first-child::before {
    left: -4px;
  }
  .buy-steps__line-item:nth-child(2)::before {
    left: 48px;
  }
  .buy-steps__line-item:nth-child(3)::before {
    left: 86px;
  }
  .buy-steps__line-item:last-child::before {
    right: -9px;
  }
}

@media (max-width: 767.98px) {
  .buy-steps__line-item:first-child::before {
    left: -4px;
  }
  .buy-steps__line-item:nth-child(2)::before {
    left: 27px;
  }
  .buy-steps__line-item:nth-child(3)::before {
    left: 48px;
  }
  .buy-steps__line-item:last-child::before {
    right: -4px;
  }
}

@media (max-width: 575.98px) {
  .buy-steps__line-item:first-child::before {
    left: -11px;
  }
  .buy-steps__line-item:nth-child(2)::before {
    left: 15px;
  }
  .buy-steps__line-item:nth-child(3)::before {
    left: 40px;
  }
  .buy-steps__line-item:last-child::before {
    right: -11px;
  }
}
.buy-steps__caption {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #222943;
  width: 100%;
  margin-bottom: 40px;
}
@media (max-width: 991.98px) {
  .buy-steps__caption {
    margin-bottom: 25px;
  }
}
@media (max-width: 575.98px) {
  .buy-steps__caption {
    font-size: 16px;
  }
}
.buy-steps__payment .buy-steps__caption {
  margin-bottom: 30px;
}

.buy-steps__info .buy-steps__caption {
  margin-bottom: 30px;
}

@media (max-width: 991.98px) {
  .buy-steps__payment {
    margin-bottom: 100px;
  }
}
@media (max-width: 767.98px) {
  .buy-steps__info.octagon {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
@media (max-width: 575.98px) {
  .buy-steps__payment {
    margin-bottom: 40px;
  }
}

.buy-steps__payment-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buy-steps__payment-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 0 5px;
  min-height: 96px;
  width: 100%;
  margin-bottom: 13px;
}

.buy-steps__payment-wrap:not(:last-child) {
  margin-right: 28px;
}

@media (max-width: 575.98px) {
  .buy-steps__payment-wrap:not(:last-child) {
    margin-right: 10px;
  }
}

.buy-steps__item {
  margin-bottom: 13px;
}

.buy-steps__actions {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.buy-steps__payment-start .buy-steps__actions {
  padding-top: 13px;
}

@media (max-width: 575.98px) {
  .buy-steps__actions {
    flex-direction: column;
  }
}

.buy-steps__checkboxes {
  padding-right: 36px;
}

@media (max-width: 575.98px) {
  .buy-steps__checkboxes {
    padding: 0 0 40px;
  }
}

.buy-steps__captcha {
  flex-grow: 1;
  padding-top: 6px;
}

.buy-steps__checkboxes .custom-control:not(:last-child) {
  margin-bottom: 9px;
}

.buy-steps__payment-start .buy-steps__checkboxes .custom-control:not(:last-child) {
  margin-bottom: 14px;
}

.buy-steps__ssl {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #78ff73;
  margin-bottom: 0;
}

.buy-steps__ssl:before {
  content: url(@/Images/cards_images/lock.svg);
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  transform: translateY(2px);
}

.buy-steps__change-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

@media (max-width: 575.98px) {
  .buy-steps__change-info {
    justify-content: space-between;
  }
}

.buy-steps__from,
.buy-steps__to {
  margin-bottom: 35px;
  position: relative;
  z-index: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.78;
  color: #222943;
  min-width: 105px;
}

.buy-steps__exchange-ic {
  display: block;
  margin: 0 20px 40px;
}

@media (max-width: 575.98px) {
  .buy-steps__exchange-ic {
    margin-left: auto;
    margin-right: auto;
  }
}

.buy-steps__name {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #5ca1ff;
  text-transform: uppercase;
  position: absolute;
  left: 0px;
  bottom: -13px;
}

.buy-steps__info-top {
  margin-bottom: 37px;
}

.buy-steps__link-recalc,
.buy-steps__info-recalc {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
}

.buy-steps__link-recalc:hover,
.buy-steps__link-recalc:focus,
.buy-steps__link-recalc:active {
  color: #78ff73;
}

.buy-steps__info-recalc {
  margin-bottom: 8px;
}

.buy-steps__link-recalc {
  display: inline-block;
  vertical-align: middle;
  color: #78ff73;
  margin-bottom: 41px;
}

.buy-steps__info-full {
  width: 100%;
  border-top: 1px solid #9a9a9a;
  padding-top: 20px;
}

.buy-steps__info-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}

.buy-steps__info-row:last-child {
  margin-bottom: 0;
}

.buy-steps__info-rate {
  font-weight: 400;
  font-size: 16px;
  line-height: 12px;
  color: #222943;
  margin-right: 10px;
}

.buy-steps__info-val {
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  color: #5ca1ff;
  text-align: right;
  word-break: break-all;
}

.buy-steps__info-wallet {
  line-height: 24px;
  transform: translateY(-6px);
}

.buy-steps__butt {
  font-weight: 400;
}

.buy-steps__butt-cancel {
  margin-right: 20px;
}

.warning-info {
  background: #fcf7d1;
  border-radius: 16px;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: #896e39;
  padding: 15px;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
@media (max-width: 767.98px) {
  .warning-info {
    font-size: 14px;
  }
}
.warning-info img {
  width: 23px;
  height: auto;
  margin-right: 10px;
  flex: none;
}

.block-table-octagon {
  margin: 0;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  padding: 40px;
}

@media (max-width: 991.98px) {
  .block-table-octagon {
    padding: 20px;
    border-radius: 25px;
  }
}

@media (max-width: 575.98px) {
  .block-table-octagon {
    margin: 0;
  }
}
.col-md-6 .block-table-octagon {
  height: 100%;
}
.table-octagon_title {
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid #9a9a9a;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #222943;
}

.block-table-octagon form {
  width: 45%;
  margin: 0 auto 40px;
}

@media (max-width: 991.98px) {
  .block-table-octagon form {
    width: 65%;
    max-width: 455px;
  }
}

@media (max-width: 767.98px) {
  .block-table-octagon form {
    width: 100%;
    max-width: 100%;
  }
}

.form-row {
  gap: 20px;
}

@media (max-width: 575.98px) {
  .form-row {
    flex-wrap: wrap;
    gap: 10px;
  }
}

.form-item {
  flex: 1;
}

@media (max-width: 575.98px) {
  .form-row:first-child .form-item:nth-child(2) label {
    width: 15px;
    white-space: nowrap;
    font-size: 13px;
  }
}

.form-item-fixed {
  width: 115px;
  flex: none;
}

.form-item-short {
  width: 75px;
  flex: none;
}

.form-item label {
  display: block;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #9a9a9a;
}
.block-table-octagon form input:not([type="submit"]) {
  border: 0.7px solid #212943;
  border-radius: 6px;
  height: 46px;
  background: #ffffff;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #212944;
  padding: 0 15px;
  width: 100%;
}
.btn-receive {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 575.98px) {
  .block-table-octagon form input:not([type="submit"]) {
    font-size: 14px;
  }
}

.block-table-octagon form input[type="submit"] {
  height: 46px;
}

.block-table-octagon form select {
  border: 0.7px solid #212943;
  border-radius: 6px;
  height: 46px;
  background: #ffffff;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #212944;
  padding: 0 15px;
  width: 100%;
  background: url(@/Images/cards_images/arrow_select.svg) no-repeat right;
  background-position-x: calc(100% - 6px);
  background-size: 17px;
  appearance: none;
  -moz-appearance: none;
}
@media (max-width: 991.98px) {
  .block-table-octagon form select {
    padding: 0 5px;
  }
}
@media (max-width: 575.98px) {
  .block-table-octagon form select {
    font-size: 14px;
  }
}
.block-table-octagon form select option {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #777e8a;
  text-align: left;
}
.form-item-united {
  display: flex;
  justify-content: space-between;
  border: 0.7px solid #212944;
  border-radius: 6px;
  overflow: hidden;
}
.block-table-octagon form .form-item-united input:not([type="submit"]),
.block-table-octagon form .form-item-united select {
  border: none;
}
.block-table-octagon form .form-item-united select {
  width: 90px;
}
.table-octagon_content {
  width: 100%;
  padding-top: 20px;
  margin-bottom: -10px;
}

@media (max-width: 991.98px) {
  .table-octagon_content {
    overflow: auto;
  }
}

.table-octagon_header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media (max-width: 991.98px) {
  .table-octagon_header {
    min-width: 815px;
  }
}

.table-octagon_header div {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #9a9a9a;
}

.table-octagon_item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
}

@media (max-width: 991.98px) {
  .table-octagon_item {
    min-width: 815px;
  }
}

.table-octagon_item div {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #9a9a9a;
}

.table-octagon_item div span {
  font-weight: 700;
}

.table-octagon_item .table-octagon_current {
  font-weight: 600;
  color: #000000;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 107%;
}

.table-octagon_item .table-octagon_current img {
  width: 20px;
  margin-right: 10px;
}

.table-octagon_current {
  width: 18%;
  flex: none;
}

.table-octagon_feep {
  width: 8%;
  flex: none;
}

.table-octagon_amount {
  width: 20%;
  flex: none;
}
.table-octagon_fee {
  width: 17%;
  flex: none;
}

.table-octagon_completion {
  width: auto;
  flex: 1;
}

.table-octagon_spend {
  width: 20%;
  flex: none;
  font-weight: 700 !important;
}

.table-octagon_btn {
  width: 115px;
  flex: none;
}
.bottom-line-btn {
  gap: 20px;
  margin-top: 40px;
}
@media (max-width: 991.98px) {
  .bottom-line-btn {
    margin-top: 25px;
  }
}
.bottom-line-btn .btn-red {
  width: 170px;
  height: 46px;
}
.bottom-line-btn .btn-back {
  height: 46px;
  width: 153px;
  line-height: 44px;
}
.block-payment-confirmation {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
  width: 100%;
}

@media (max-width: 575.98px) {
  .block-payment-confirmation {
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
  }
}

.payment-confirmation_credited {
  width: 100%;
}

.payment-confirmation_label {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #777e8a;
}

.payment-confirmation_value {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.payment-confirmation_value span {
  color: #f30909;
}
.block-table-octagon_text {
  width: 100%;
}
.block-table-octagon_text p {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  width: 94%;
}
.block-table-octagon_text p:not(:last-child) {
  margin-bottom: 15px;
}
.block-table-octagon_text ul {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 15px;
  width: 94%;
}

.block-table-octagon_text li {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 15px;
  padding-left: 20px;
  position: relative;
}

.block-table-octagon_text li:before {
  content: "";
  position: absolute;
  top: 7px;
  left: 8px;
  background: #000000;
  width: 4px;
  height: 4px;
  clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
}

.block-table-octagon_text *:last-child {
  margin-bottom: 0;
}

.block-qr-code {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767.98px) {
  .block-qr-code {
    margin-top: 20px;
    margin-bottom: 20px;
    height: auto;
  }
}

.block-qr-code_text {
  text-align: center;
}

.block-qr-code_img {
  width: 205px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.block-qr-code_val {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 10px;
}

.block-qr-code_val-from {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #777e8a;
  margin-bottom: 20px;
}
.instruction-page-style {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
@media (max-width: 534px) {
  .instruction-page-style {
    flex-direction: column;
  }
  .instruction-actions {
    width: 100%;
  }
  .instruction-page-style button {
    width: 100% !important;
  }
  .instruction-page-style .btn-back::before {
    left: 139px;
  }
}
.instruction-actions {
  display: flex;
  align-items: center;
  gap: 16px;
}
.instruction-actions ul {
  color: red;
  list-style-type: disc;
}

.loading_box {
  filter: blur(5px);
}
.loading_box-text {
  color: #212842;
  font-weight: bold;
  position: absolute;
  font-size: 22px;
}

.btn-copy {
  background-color: transparent;
  width: 20px;
  height: 20px;
  border: none;
  background-image: url(@/Images/cards_images/copy.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  margin: 0 auto;
  cursor: pointer;
}

.block-qr-code_code {
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #777e8a;
  margin-bottom: 10px;
}

.block-qr-code_time {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #777e8a;
}

/*--------------------------------------------------------------
# end buy step 1
--------------------------------------------------------------*/

.main-form-cards {
  background: #ffffff;
  padding: 60px 0 40px;
  position: relative;
  width: 100%;
  overflow: hidden;
}

@media (max-width: 575.98px) {
  .main-form-cards {
    padding-top: 40px;
  }
}

.main-form-cards_header {
  text-align: center;
  margin-bottom: 30px;
}

.main-form-cards_header.mb40 {
  margin-bottom: 40px;
}

@media (max-width: 575.98px) {
  .main-form-cards_header.mb40 {
    margin-bottom: 30px;
  }
}

.main-form-cards_logo.mb40 {
  margin-bottom: 40px;
}

@media (max-width: 575.98px) {
  .main-form-cards_logo.mb40 {
    margin-bottom: 30px;
  }
}

.main-form-cards_logo {
  width: 70px;
  margin: 0 auto 20px;
  display: inline-block;
}

@media (max-width: 575.98px) {
  .main-form-cards_logo {
    margin-bottom: 30px;
  }
}

.main-form-cards_logo img {
  width: 100%;
  height: auto;
}

.main-form-cards_title {
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: #000000;
  margin-bottom: 20px;
}

@media (max-width: 575.98px) {
  .main-form-cards_title {
    margin-bottom: 10px;
  }
}

.main-form-cards_subtitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
}

@media (max-width: 575.98px) {
  .main-form-cards_subtitle {
    font-size: 14px;
    line-height: 18px;
  }
  .main-form-cards_subtitle--dop {
    font-size: 16px;
    line-height: 21px;
  }
}

.main-form-cards_flex {
  display: flex;
  justify-content: center;
  gap: 20px;
  max-width: 662px;
  margin: 0 auto;
}

@media (max-width: 767.98px) {
  .main-form-cards_flex {
    flex-wrap: wrap;
  }
}

@media (max-width: 575.98px) {
  .main-form-cards_flex {
    gap: 30px;
  }
}

.main-form-cards_item {
  border: 1px solid #e8e8e8;
  text-align: center;
  padding: 40px 30px;
  flex: 1;
}

@media (max-width: 767.98px) {
  .main-form-cards_item {
    width: 100%;
    flex: none;
  }
}

@media (max-width: 575.98px) {
  .main-form-cards_item {
    padding: 25px;
  }
}

.main-form-cards_item_icon {
  background: #f3f7fd;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 15px;
}

@media (max-width: 575.98px) {
  .main-form-cards_item_icon {
    margin-bottom: 25px;
  }
}

.main-form-cards_item_icon img {
  width: 30px;
}

.main-form-cards_item_title {
  font-weight: 500;
  font-size: 16px;
  line-height: 107%;
  text-align: center;
  color: #303030;
  margin-bottom: 10px;
}

.main-form-cards_item_description {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #777e8a;
  margin-bottom: 20px;
}

@media (max-width: 575.98px) {
  .main-form-cards_item_description {
    margin-bottom: 25px;
  }
}

.main-form-cards_btn {
  display: inline-block;
  cursor: pointer;
}

.main-form-cards_description {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #777e8a;
  margin-top: 10px;
}

@media (max-width: 575.98px) {
  .main-form-cards_description {
    font-size: 14px;
    line-height: 18px;
  }
}

.main-form-cards_form {
  max-width: 500px;
  margin: 0 auto;
}

.main-form-cards-back {
  position: absolute;
  top: 60px;
  left: 44px;
  z-index: 999;
  font-weight: 500;
  background: transparent;
  border: none;
  color: #3f48cc;
  font-size: 14px;
  line-height: 18px;
  padding-left: 22.7px;
  cursor: pointer;
}

@media (max-width: 767.98px) {
  .main-form-cards-back {
    display: none;
  }
}

.main-form-cards-back:before {
  content: "";
  width: 9.4px;
  height: 16px;
  position: absolute;
  top: calc(50% - 8px);
  left: 0px;
  background-image: url(@/Images/cards_images/back-blue.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
}

.main-form-cards_form select {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  font-weight: 400;
  font-size: 12px;
  line-height: 107%;
  color: #000000;
  height: 40px;
  padding-left: 0;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  border-radius: 6px;
  -webkit-padding-start: 10px;
  -moz-padding-start: 10px;
  -moz-appearance: none;
}

.main-form-cards_form select::-ms-expand {
  display: none;
}

@media (max-width: 575.98px) {
  .main-form-cards_form select {
    font-size: 14px;
    height: 46px;
    -webkit-padding-start: 15px;
    -moz-padding-start: 15px;
  }
}

.main-form-cards_form .select-form {
  position: relative;
}

.main-form-cards_form .select-form:after {
  content: "";
  position: absolute;
  right: 10px;
  top: calc(50% - 3px);
  width: 10px;
  height: 6px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(@/Images/cards_images/select.svg);
  z-index: 2;
}

@media (max-width: 575.98px) {
  .main-form-cards_form .select-form:after {
    right: 15px;
  }
}

.main-form-cards_form .select-card {
  position: relative;
}

.main-form-cards_form .select-card select,
.main-form-cards_form .select-card select option {
  -webkit-padding-start: 40px;
  -moz-padding-start: 40px;
}

@media (max-width: 575.98px) {
  .main-form-cards_form .select-card select,
  .main-form-cards_form .select-card select option {
    -webkit-padding-start: 45px;
    -moz-padding-start: 45px;
  }
}

.main-form-cards_form .select-card:before {
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  top: 8px;
  left: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(@/Images/cards_images/type-card-blue.svg);
  z-index: 2;
}

@media (max-width: 575.98px) {
  .main-form-cards_form .select-card:before {
    top: 10px;
    left: 15px;
  }
}

.main-form-cards_form .select-card-green:before {
  background-image: url(@/Images/cards_images/card-red.svg);
}

.main-form-cards_form .mb10 {
  margin-bottom: 10px;
}

.main-form-cards_form .mb15 {
  margin-bottom: 15px;
}

.main-form-cards_form .mb20 {
  margin-bottom: 20px;
}

.main-form-cards_form .mb30 {
  margin-bottom: 30px;
}

.main-form-cards_form .mb33 {
  margin-bottom: 33px;
}

@media (max-width: 575.98px) {
  .main-form-cards_form .mb33 {
    margin-bottom: 30px;
  }
}

.main-form-cards_form .mb40 {
  margin-bottom: 40px;
}

@media (max-width: 575.98px) {
  .main-form-cards_form .mb40 {
    margin-bottom: 30px;
  }
}

.main-form-cards_form input:not([type="submit"]) {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  font-weight: 400;
  font-size: 12px;
  line-height: 107%;
  color: #000000;
  padding: 0 10px;
  height: 40px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 6px;
}

@media (max-width: 575.98px) {
  .main-form-cards_form input:not([type="submit"]) {
    padding: 0 15px;
    font-size: 14px;
    height: 46px;
  }
}

.main-form-cards_form textarea {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  font-weight: 400;
  font-size: 12px;
  line-height: 107%;
  color: #000000;
  padding: 13px 10px;
  height: 90px;
  width: 100%;
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media (max-width: 575.98px) {
  .main-form-cards_form textarea {
    padding: 15px 15px;
    font-size: 14px;
  }
}

.main-form-cards_form input:not([type="submit"])::placeholder,
.main-form-cards_form textarea::placeholder {
  color: #777e8a;
}

.main-form-cards_form label {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #777e8a;
  display: block;
}

@media (max-width: 575.98px) {
  .main-form-cards_form label {
    font-size: 14px;
    line-height: 18px;
  }
}

.main-form-cards_form .grey-form {
  background: #f4f4f4;
  width: 100%;
  padding: 20px;
  border-radius: 16px;
}

.main-form-cards_form .line-form {
  background: #e9e9e9;
  width: 100%;
  height: 1px;
}

.main-form-cards_form .info-form-block {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.main-form-cards_form .info-form {
  max-width: 30%;
  min-width: 30%;
  flex: 1;
}

@media (max-width: 575.98px) {
  .main-form-cards_form .info-form {
    max-width: 50%;
  }
}

.main-form-cards_form .info-form-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 107%;
  color: #777e8a;
  margin-bottom: 10px;
}

@media (max-width: 575.98px) {
  .main-form-cards_form .info-form-label {
    font-size: 14px;
  }
}

.main-form-cards_form .info-form-value {
  font-weight: 500;
  font-size: 12px;
  line-height: 107%;
  color: #000000;
}

@media (max-width: 575.98px) {
  .main-form-cards_form .info-form-value {
    font-size: 14px;
  }
}

.main-form-cards_form .label-checkbox {
  position: relative;
  font-weight: 400;
  font-size: 12px;
  line-height: 107%;
  color: #777e8a;
  display: flex;
  align-items: center;
  gap: 10px;
}

@media (max-width: 575.98px) {
  .main-form-cards_form .label-checkbox {
    font-size: 14px;
  }
}

.main-form-cards_form .label-checkbox:before {
  content: "";
  display: block;
  width: 25px;
  height: 25px;
  min-width: 25px;
  border: 1px solid #212842;
  border-radius: 3px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.main-form-cards_form .input-checkbox {
  position: absolute;
  z-index: -999;
  opacity: 0;
  width: 0 !important;
  height: 0 !important;
  overflow: hidden;
}

.main-form-cards_form input:checked + .label-checkbox:before {
  background-image: url(@/Images/cards_images/check-silver.svg);
  border-color: transparent;
}

.main-form-cards_form input[type="submit"] {
  width: 100%;
}

.main-form-cards_form .btn-long {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.main-form-cards_form .radio-form {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  padding: 15px 0px 15px 50px;
}

@media (max-width: 575.98px) {
  .main-form-cards_form .radio-form {
    padding-left: 45px;
  }
}

.main-form-cards_form .radio-input {
  position: absolute;
  z-index: -999;
  opacity: 0;
  width: 0 !important;
  height: 0 !important;
  overflow: hidden;
}

.main-form-cards_form .radio-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #777e8a;
  display: block;
  margin-bottom: 0;
  position: relative;
}

.main-form-cards_form .radio-label:before {
  content: "";
  border: 1px solid #777e8a;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  left: -30px;
  top: 8px;
}

.main-form-cards_form .radio-label:after {
  content: "";
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  left: -27px;
  top: 11px;
  background: #5ca1ff;
  opacity: 0;
}

.main-form-cards_form .radio-label span {
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 107%;
  color: #777e8a;
  margin-top: 5px;
}

.main-form-cards_form .radio-label span.price {
  position: absolute;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #777e8a;
  right: 20px;
  top: 9px;
  margin: 0;
}

@media (max-width: 575.98px) {
  .main-form-cards_form .radio-label span.price {
    right: 15px;
  }
}

.main-form-cards_form input:checked + .radio-label span.price {
  color: #5ca1ff;
}

.main-form-cards_form input:checked + .radio-label:after {
  opacity: 1;
}

.main-form-cards_form input:checked + .radio-label:before {
  border-color: #5ca1ff;
}

.main-form-cards_form .iti {
  width: 100%;
  margin-bottom: 10px;
}

.main-form-cards_form .iti input {
  margin-left: 80px;
  width: calc(100% - 80px);
}

.iti__flag-container {
  border: 1px solid #f0f0f0;
  width: 70px;
}

.country-select {
  width: 100%;
  margin-bottom: 10px;
}

.country-select.inside input,
.country-select.inside input[type="text"] {
  padding-left: 51px;
  padding-top: 0;
  position: relative;
  z-index: 1;
}

.country-select.inside .flag-dropdown {
  width: 100%;
  z-index: 2;
}

.country-select.inside .flag-dropdown:before {
  content: "";
  position: absolute;
  right: 10px;
  top: calc(50% - 3px);
  width: 10px;
  height: 6px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(@/Images/cards_images/select.svg);
}

@media (max-width: 575.98px) {
  .country-select.inside .flag-dropdown:before {
    right: 15px;
  }
}

.country-select.inside .selected-flag {
  width: 100%;
  padding-left: 10px;
}

.country-select.inside .flag-dropdown:hover .selected-flag {
  background-color: transparent;
}

.country-select .selected-flag .arrow {
  display: none !important;
}

.main-form-cards_step {
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  margin: 40px auto;
}

@media (max-width: 575.98px) {
  .main-form-cards_step {
    max-width: 346px;
    margin: 30px auto;
  }
}

.main-form-cards_one-step {
  text-align: center;
  position: relative;
  line-height: 1;
}

.main-form-cards_one-step:nth-child(2) {
  margin-left: 12px;
}

@media (max-width: 575.98px) {
  .main-form-cards_one-step {
    max-width: 64px;
  }
  .main-form-cards_one-step:nth-child(2) {
    margin-left: 0;
  }
}

.main-form-cards_one-step:after {
  content: "";
  width: 159px;
  height: 1px;
  position: absolute;
  top: 9.5px;
  left: calc(50% + 20px);
  background: #f2f2f2;
}

.main-form-cards_one-step:first-child:after {
  width: 162px;
}

@media (max-width: 575.98px) {
  .main-form-cards_one-step:after,
  .main-form-cards_one-step:first-child:after {
    width: 101px;
  }
}

.main-form-cards_one-step:last-child:after {
  display: none;
}

.main-form-cards_one-step_number {
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #68e063;
  width: 20px;
  height: 20px;
  border: 2px solid #68e063;
  background: #ffffff;
  border-radius: 50%;
  margin: 0 auto 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.main-form-cards_one-step_title {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #777e8a;
}

.main-form-cards_one-step--done .main-form-cards_one-step_number {
  border-color: #3f48cc;
  background: #3f48cc;
  color: #ffffff;
}

.main-form-cards_one-step--done .main-form-cards_one-step_title {
  color: #3f48cc;
  font-weight: 600;
}

.main-form-cards_one-step--done:after {
  background: #3f48cc;
}

.main-form-cards .information-block {
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 107%;
  color: #777e8a;
  background: #f3f7fd;
  padding: 13px 10px;
  min-height: 40px;
  display: flex;
  align-items: end;
}

.main-form-cards .information-block img {
  width: 16px;
  margin-right: 10px;
  flex: none;
}

@media (max-width: 575.98px) {
  .main-form-cards .information-block {
    font-size: 14px;
    line-height: 18px;
    align-items: flex-start;
  }
}

.range-form {
  padding: 18px 10px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  position: relative;
  z-index: 1;
}

@media (max-width: 575.98px) {
  .range-form {
    padding: 15px 15px 20px;
  }
}

.range-form label {
  margin-bottom: 6px;
  font-weight: 400;
}

@media (max-width: 575.98px) {
  .range-form label {
    margin-bottom: 7px;
  }
}

.range-form:before {
  z-index: -1;
  content: "";
  position: absolute;
  bottom: 24px;
  left: 10px;
  width: calc(100% - 20px);
  height: 6px;
  background: #f3f7fd;
}

@media (max-width: 575.98px) {
  .range-form:before {
    left: 15px;
    width: calc(100% - 30px);
    bottom: 26px;
  }
}

.range-procent {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
}

@media (max-width: 575.98px) {
  .range-procent {
    top: 8px;
    right: 15px;
  }
}

.range-procent span {
  background: #f3f7fd;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 107%;
  color: #777e8a;
}

.range-procent .range-procent-val {
  width: 40px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #f0f0f0;
}

.range-form input[type="range"] {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  height: auto;
  padding: 0;
  margin-top: 0px;
  background: linear-gradient(to right, #3f48cc 0%, #3f48cc 25%, transparent 25%, transparent 100%);
}

.range-form input[type="range"]::-webkit-slider-runnable-track {
  border-radius: 0;
  height: 6px;
  border: none !important;
  background: transparent;
}

.range-form input[type="range"]::-moz-range-track {
  border-radius: 0;
  height: 6px;
  border: none !important;
  background: transparent;
}

.range-form input[type="range"]::-webkit-slider-thumb {
  background: #3f48cc;
  border: 1px solid #3f48cc;
  border-radius: 50%;
  cursor: pointer;
  width: 16px;
  height: 16px;
  -webkit-appearance: none;
  margin-top: -5px;
}

.range-form input[type="range"]::-moz-range-thumb {
  background: #3f48cc;
  border: 1px solid #3f48cc;
  border-radius: 50%;
  cursor: pointer;
}

.range-form input[type="range"]::-ms-fill-lower {
  background: #3f48cc;
}

.range-form input[type="range"]::-ms-fill-upper {
  height: 10px;
  background: red;
  border: 0;
  margin-top: 0;
}

.field-form {
  width: 100%;
  position: relative;
  text-align: center;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  padding: 20px;
}

.field-file {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.field-image {
  width: 30px;
  height: auto;
  margin: 0 auto 15px;
}

.field-fake {
  font-weight: 400;
  font-size: 16px;
  line-height: 107%;
  color: #000000;
  margin-bottom: 10px;
}

.field-fake span {
  color: #3f48cc;
}

.field-format {
  font-weight: 400;
  font-size: 12px;
  line-height: 107%;
  color: #777e8a;
}

.field-button {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  color: transparent;
  font-size: 0;
  border-radius: 0;
  border: none;
  cursor: pointer;
}

.percon-block {
  background: #ffffff;
  border: 1px solid #f0f0f0;
}

.percon-block-body {
  padding: 10px 20px 20px;
}

.percon-block .percon-block-brn-send {
  margin-left: 0;
}

.percon-block-brn-add {
  background: transparent;
  border: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #3f48cc;
  cursor: pointer;
  display: block;
}

@media (max-width: 575.98px) {
  .percon-block-brn-add {
    font-size: 14px;
    line-height: 18px;
  }
}

.percon-block-header {
  padding: 20px 20px 10px;
  position: relative;
  border-bottom: 1px solid #f0f0f0;
  position: relative;
}

.percon-block-status {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #777e8a;
  background: #f0f0f0;
  padding: 5px;
  display: inline-block;
}

.percon-block-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  display: flex;
  align-items: center;
}

.percon-block-title .info {
  width: 16px;
  height: 16px;
  flex: none;
  display: inline-block;
  margin-right: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(@/Images/cards_images/info.svg);
}

.percon-block-btn {
  display: flex;
  align-items: center;
  top: 24px;
  right: 20px;
  position: absolute;
  gap: 20px;
}

.percon-block-btn-close {
  width: 10px;
  height: 10px;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  background-image: url(@/Images/cards_images/arrow-down.svg);
}

.percon-block-btn-more {
  width: 15px;
  height: 3px;
  display: block;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(@/Images/cards_images/dot3.svg);
  position: relative;
}

.percon-block-hover {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 5px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.12);
  z-index: 999;
  padding: 10px;
  width: 152px;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: 0.3s all;
}

.percon-block-btn-more:hover .percon-block-hover {
  opacity: 1;
  height: auto;
  transition: 0.3s all;
}

.percon-block-hover-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.percon-block-hover .line {
  width: 100%;
  height: 1px;
  background: #ebebeb;
  margin: 10px 0;
}

.change-number {
  width: 16px;
  height: 16px;
  border: none;
  background-image: url(@/Images/cards_images/pencilg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.person-number {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #777e8a;
  display: flex;
  align-items: center;
  gap: 10px;
}

.person-number-send {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}

@media (max-width: 575.98px) {
  .person-number-send {
    font-size: 14px;
    line-height: 18px;
  }
}
.pages-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
@media (max-width: 575.98px) {
  .pages-pagination {
    flex-wrap: wrap;
    gap: 15px;
  }
}
.pages-pagination_title {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #9a9a9a;
}
.pages-pagination_number {
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  gap: 20px;
}
@media (max-width: 767.98px) {
  .pages-pagination_number {
    gap: 15px;
  }
}
@media (max-width: 575.98px) {
  .pages-pagination_number {
    gap: 10px;
  }
}
.pages-pagination_number-li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.pages-pagination_number-li a {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5faff;
  border: 0.399215px solid #121933;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #121933;
}
.pages-pagination_number-li--active a {
  background: #5ca1ff;
  border-color: #5ca1ff;
  color: #fff;
}
.pages-pagination_number-li--next a {
  width: auto;
  padding: 0 14px;
  position: relative;
}
.pages-pagination_number-li--next a::after {
  content: "";
  display: inline-block;
  position: relative;
  margin-left: 4px;
  width: 6px;
  height: 6px;
  border-top: 1px solid #121933;
  border-right: 1px solid #121933;
  transform: rotate(45deg);
}
.account {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 50px;
}
@media (max-width: 1300px) {
  .account {
    margin-bottom: 30px;
  }
}
.account-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
@media (max-width: 767.98px) {
  .account-flex {
    flex-wrap: wrap;
  }
}
.account_block {
  padding: 30px;
  background: #ffffff;
  border-radius: 21px;
  flex: 1;
  width: calc(50% - 12px);
}
@media (max-width: 991.98px) {
  .account_block {
    padding: 20px;
  }
}
@media (max-width: 767.98px) {
  .account_block {
    min-width: 100%;
  }
}
.account_block-title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #212946;
  margin-bottom: 10px;
}
.account_block-desc {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #212946;
  max-width: 300px;
  margin-bottom: 30px;
}
.account_verification {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.account_verification-block {
  padding: 14px 30px;
  background: #f4f4f4;
  border-radius: 16px;
  width: calc((100% - 15px) / 2);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 991.98px) {
  .account_verification-block {
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .account_verification-block {
    padding: 14px 16px;
  }
}
@media (max-width: 575.98px) {
  .account_verification-block {
    flex-wrap: wrap;
    gap: 10px;
  }
}
.account_verification-block--full {
  width: 100%;
}
.account_verification-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media (max-width: 767.98px) {
  .account_verification-content {
    gap: 5px;
  }
}
.account_verification-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #121933;
}
.account_verification-desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #9a9a9a;
}
@media (max-width: 767.98px) {
  .account_verification-desc {
    font-size: 12px;
  }
}
.account_verification-btn {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  border-radius: 6px;
  padding: 12px 13px;
  width: 120px;
  text-align: center;
}
.account_verification-title--verified {
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
  color: #4fb044;
  background: #e4f8dd;
  border-radius: 23px;
  padding: 6px 10px;
  margin-left: 10px;
}
.account_verification-title--notverified {
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
  color: #ef382a;
  background: #ffe0d6;
  border-radius: 23px;
  padding: 6px 10px;
  margin-left: 10px;
}
.label-information {
  width: 15px;
  height: 15px;
  margin-left: 5px;
  display: inline-block;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(@/Images/cards_images/information.svg);
  vertical-align: middle;
}
.account_wallet {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 24px;
}
@media (max-width: 991.98px) {
  .account_wallet {
    gap: 20px;
  }
}

.account_wallet-block {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
  width: calc(50% - 12px);
}
@media (max-width: 991.98px) {
  .account_wallet-block {
    gap: 10px;
  }
}
@media (max-width: 767.98px) {
  .account_wallet-block {
    width: 100%;
  }
}
.account_wallet-label {
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #9a9a9a;
}
.account_wallet-btn {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  border-radius: 6px;
  padding: 12px 13px;
  width: 90px;
  text-align: center;
  flex: none;
}
.account_wallet-input {
  flex: 1;
  border: 0.7px solid #9a9a9a;
  border-radius: 6px;
  height: 36px;
  padding: 0 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #9a9a9a;
}

.account_edit {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 24px;
  margin-top: 20px;
}
@media (max-width: 575.98px) {
  .account_edit {
    gap: 20px 10px;
  }
}
.account_edit-block {
  width: calc(50% - 12px);
}

@media (max-width: 575.98px) {
  .account_edit-block {
    width: calc(50% - 5px);
  }
}
.account_edit-block--full {
  width: 100%;
}
.account_edit-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #9a9a9a;
  margin-bottom: 10px;
  width: 100%;
}
.account_edit-input,
.account_edit-select {
  border: 0.7px solid #9a9a9a;
  border-radius: 6px;
  width: 100%;
  height: 36px;
  padding: 0 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #212944;
}
@media (max-width: 575.98px) {
  .account_edit-input,
  .account_edit-select {
    padding: 0 5px;
  }
}
.account_edit-btn {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  border-radius: 6px;
  padding: 12px 13px;
  width: 120px;
  text-align: center;
}
.account_edit-radio {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
.account_edit-radio label {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #212842;
  position: relative;
}
.account_edit-radio label:before {
  content: "";
  display: inline-block;
  border: 0.7px solid #9a9a9a;
  border-radius: 3px;
  margin-right: 12px;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  background-position: center;
  background-repeat: no-repeat;
}
.account_edit-radio input {
  visibility: hidden;
  width: 0;
  height: 0;
  position: absolute;
  z-index: -99999;
}
.account_edit-radio input:checked + label:before {
  background-image: url(@/Images/cards_images/check-mark.svg);
}
.account_avatar {
  border: 0.7px solid #9a9a9a;
  border-radius: 6px;
  padding: 20px;
  position: relative;
}
@media (max-width: 575.98px) {
  .account_avatar {
    padding: 15px;
  }
}
.account_avatar-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.65;
  text-transform: uppercase;
  color: #212842;
  margin-bottom: 10px;
  max-width: 200px;
}
.account_avatar-desc {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.4;
  color: #777e8a;
  max-width: 230px;
}
.account_avatar-desc span {
  color: #5ba1ff;
}
.account_avatar-info {
  position: absolute;
  right: 20px;
  top: 20px;
}
.account_information {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 0;
}
.account_information-block {
  width: 25%;
}
@media (max-width: 767.98px) {
  .account_information-block {
    width: 33%;
  }
}
@media (max-width: 575.98px) {
  .account_information-block {
    width: 50%;
  }
}
.account_information-label {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #383f59;
  margin-bottom: 10px;
}
.account_information-value {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #212946;
}
.account_crypto {
  text-align: center;
  padding: 30px;
  background: #5ca1ff;
  border-radius: 21px;
  max-width: 340px;
  width: 31%;
  flex: none;
}
@media (max-width: 991.98px) {
  .account_crypto {
    min-width: 100%;
  }
}
.CookieConsent {
  z-index: 3001 !important;
}
.account_crypto-img {
  width: 60px;
  height: auto;
  margin: 0 auto 24px;
}
.account_crypto-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 8px;
}
.account_crypto-desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  opacity: 0.9;
  margin-bottom: 17px;
}
.account_crypto-btn {
  display: inline-block;
  margin: 0 auto;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #11172f;
  padding: 12px 42px;
  border-radius: 6px;
}
.account_graph {
  border-top: 1px solid #c4c4c4;
  width: 100%;
  display: block;
  padding-top: 20px;
  margin-top: 20px;
}
.account_graph-list {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 20px;
}
.account_graph-btn {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding: 10px;
  border-radius: 7.93236px;
  color: #121933;
  cursor: pointer;
}
.account_graph-btn.active {
  background: #5ca1ff;
  color: #ffffff;
}
#myChart {
  width: 100%;
  height: 100%;
}
.account_graph-info {
  width: 200px;
  display: inline-block;
}
.account_graph-select-1 {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #121933;
  padding: 0;
  border: none;
  border-radius: 0;
  display: block;
  margin-bottom: 5px;
}
.account_graph-select-2 {
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  color: #121933;
  padding: 0;
  border: none;
  border-radius: 0;
  display: block;
}
.account_graph-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #9a9a9a;
  margin-bottom: 5px;
}
@media (max-width: 575.98px) {
  .account_graph-title {
    margin-top: 20px;
  }
}
.account_graph-value {
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  color: #121933;
}
.account_graph-value span.drop {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #e41f20;
  position: relative;
  margin-left: 5px;
  vertical-align: text-top;
  margin-top: 5px;
  display: inline-block;
}
.account_graph-value span.drop:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  background-image: url(@/Images/cards_images/drop.svg);
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;
}
.text-success {
  color: #36c640 !important;
}

.text-danger {
  color: #f30909 !important;
}

.text-primary {
  color: #2048e6 !important;
}

.text-dark {
  color: #0d1118 !important;
}

.text-secondary {
  color: #0d1118 !important;
}

.text-light {
  color: #0d1118 !important;
}

.react-datepicker-popper {
  z-index: 5;
}
.main-form-cards_form .intl-tel-input {
  width: 100%;
  margin-bottom: 10px;
}

.Form-InputBox {
  position: relative;
  width: 100%;
  border-radius: 6px !important;
}

.table-orders_cards-item .table-orders_cards-filter_statust.card-status-closed {
  color: red;
}

.table-orders_cards-item .table-orders_cards-filter_statust.card-status-not_active {
  color: var(--bs-orange);
}

.table-orders_cards-item .table-orders_cards-filter_statust.card-status-active {
  color: green;
}

.table-orders_cards-item .table-orders_cards-filter_statust.card-status-waiting {
  color: var(--bs-orange);
}
.table-orders_cards-item .table-orders_cards-filter_statust.card-status-blocked {
  color: #3f48cc;
}
.table-orders_cards-item .table-orders_cards-filter_statust.card-status-locked {
  color: var(--bs-red);
}

.disabled-input {
  background-color: #f4f4f4 !important;
  color: #777e8a !important;
}

.change-password-form .allow-dropdown {
  width: 100%;
  margin-bottom: 20px;
}
.intl-tel-input {
  z-index: 500;
}

.country-list {
  font-size: 12px;
}
.language-select {
  border: 1px solid #777e8a;
  border-radius: 6px;
  background: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: white;
  padding: 8px 16px;
  padding-right: 40px;
  background: url(src/Images/cards_images/select.svg) no-repeat right;
  background-position-x: calc(100% - 8px);
  background-position-y: calc(50% + 1px);
  background-size: 12px;
  appearance: none;
  outline: none;
  -moz-appearance: none;
  margin: 0 !important;
}

.emptyHint {
  color: #9a9a9a !important;
  border: 1px solid #9a9a9a !important;
}

.Loading__Page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f5faff;
}

.Loading__Page h4 {
  margin-top: 16px;
}
body > iframe:last-child {
  width: 0 !important;
  height: 0 !important;
  pointer-events: none;
}
