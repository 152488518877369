@import "../../common/variables";
@import "../../common/mixins";

.legal-content {
    & strong {
        font-family: 'MacPawFixelDisplay-Medium'; }
    & a {
        color: var(--primary-purple); }
    & p:not(.last_updated) {
        font-size: 22px;
        font-family: 'MacPawFixelDisplay-Regular';
        font-weight: 400;
        line-height: normal;
        margin-block: 26px; }
    & ol>li, ul>li {
        padding-bottom: 40px;
        & b {
            display: block;
            font-family: 'MacPawFixelDisplay-Medium';
            margin-bottom: 16px; } }
    & h6 {
        font-size: 22px;
        font-family: 'MacPawFixelDisplay-Bold';
        &:has(+ ul, + ol, + h6) {
            margin-bottom: 21px !important; } }
    .legal__inner-list {
        border: 1px solid red;
        & li {
            padding-top: 16px;
            padding-bottom: 0; } }
    .last_updated {
        font-size: 16px;
        font-family: "MacPawFixelDisplay-Medium";
        line-height: normal;
        color: var(--dark-3);
        margin-bottom: 40px; } }
