@import "../../common/variables";
@import "../../common/mixins";

.Exchange-Block {
  box-shadow: 0px 4px 74px rgba(0, 0, 0, 0.44);
  border-radius: 41px;
  min-height: 186px;
  padding: 32px 61px 32px 57px;

  &--DarkBlue {
    background-color: $color-third; }

  &--Red {
    background-color: $color-second; }

  @include max-w($tablet) {
    padding: 27px 25px 38px; }

  &--Compact {
    padding: 27px 25px 38px; } }

.Exchange-Block__Inner {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 25px;

  @include max-w($tablet) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px; }

  .Exchange-Block--Compact & {
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px; } }

.Exchange-Block__Input {
  margin-right: 31px;
  width: 100%;

  @include max-w($tablet) {
    margin-right: 0;
    margin-bottom: 50px;

    &:nth-child(2) {
      margin-bottom: 28px; }

    &:nth-child(1) {
      .Form-Input__Select {
        z-index: 2; } }

    &:nth-child(2) {
      .Form-Input__Select {
        z-index: 1; } }

    &:last-child {
      margin-bottom: 30px; } }

  .Exchange-Block--Compact & {
    margin-right: 0;
    margin-bottom: 25px;

    &:nth-child(2) {
      margin-bottom: 30px; }

    &:nth-child(1) {
      .Form-Input__Select {
        z-index: 2; } }

    &:nth-child(2) {
      .Form-Input__Select {
        z-index: 1; } }

    &:last-child {
      margin-bottom: 30px; } } }

.Exchange-Block__Info {
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  @include max-w($mobile_md) {
    font-size: 12px;
    line-height: 14px; }

  span {
    cursor: pointer; }

  .Exchange-Block--DarkBlue & {
    span {
      color: $color-primary; } } }
