.why {
  padding: 46px 0;
  font-family: "MacPawFixelDisplay-Light";
}

@media (max-width: 768px) {
  .why {
    padding: 127px 0 40px;
  }
}

.why__h2 {
  margin: 0 0 26px !important;
  text-align: center;
}

@media (max-width: 768px) {
  .why__h2 {
    font-size: 40px !important;
  }
}

.why__row {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 992px) {
  .why__row {
    flex-direction: column;
    justify-content: center;
  }
}

.why__row_fast {
  margin-bottom: 33px;
}

@media (max-width: 768px) {
  .why__row_fast {
    margin-bottom: 43px;
  }
}

.why__row_fast img {
  max-width: 571px;
  height: 598px;
}

@media (max-width: 768px) {
  .why__row_fast img {
    max-width: 100%;
    height: 184px;
    margin-bottom: 8px;
  }
}

picture {
  margin: auto;
}

@media (max-width: 768px) {
  picture {
    margin: 0;
  }
}

.why__row_middle {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

@media (max-width: 768px) {
  .why__row_middle {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
}

.why__fast {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 0 0 29px;
}

@media (max-width: 998px) {
  .why__fast {
    padding: 0;
    width: 100%;
  }
}

.why__fast-title {
  font-family: "MacPawFixelDisplay-Bold";
  font-size: 40px;
  line-height: normal;
  text-transform: uppercase;
  margin: 0 0 29px;
}

@media (max-width: 768px) {
  .why__fast-title {
    font-size: 20px;
    margin: 0 0 10px;
  }
}

.why__fast-text {
  margin: 0;
  font-size: 20px;
  line-height: normal;
}

@media (max-width: 768px) {
  .why__fast-text {
    font-size: 18px;
  }
}

.why__row-item {
  margin-bottom: 37px;
}

@media (max-width: 768px) {
  .why__row-item:nth-child(2) {
    margin-bottom: 48px;
  }
}

.why__row-item img {
  margin-bottom: 10px;
  height: 289px;
}

@media (max-width: 768px) {
  .why__row-item img {
    height: 184px;
    margin-bottom: 11px;
  }
}

.why__row-item-title {
  font-family: "MacPawFixelDisplay-Bold";
  font-size: 40px;
  line-height: normal;
  text-transform: uppercase;
  margin: 0 0 6px;
}

@media (max-width: 768px) {
  .why__row-item-title {
    font-size: 20px;
  }
}

.why__row-item-text {
  margin: 0;
  font-size: 22px;
  line-height: normal;
}

@media (max-width: 768px) {
  .why__row-item-text {
    font-size: 18px;
  }
}

.why__row-full img {
  margin-bottom: 13px;
  height: 357px;
}

@media (max-width: 768px) {
  .why__row-full img {
    height: 184px;
  }
}

.why__row-full-title {
  font-family: "MacPawFixelDisplay-Bold";
  font-size: 40px;
  line-height: normal;
  text-transform: uppercase;
  margin: 0 0 6px;
}

@media (max-width: 768px) {
  .why__row-full-title {
    font-size: 20px;
  }
}

.why__row-full-text {
  margin: 0;
  font-size: 22px;
  line-height: normal;
}

.why__row-full-text {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
}

@media (max-width: 768px) {
  .why__row-full-text {
    grid-template-columns: 1fr;
    grid-gap: 6px;
  }
}

.why__row-full-text p {
  margin: 0;
  font-size: 22px;
  line-height: normal;
}

@media (max-width: 768px) {
  .why__row-full-text p {
    font-size: 18px;
  }
}
