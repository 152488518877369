@import "../../common/variables";
@import "../../common/mixins";

.Nav__Menu {
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.17);
  border-radius: 16px;
  background-color: $color-primary;
  padding: 30px 40px;
  display: flex;

  .Nav--Red & {
    background-color: #E93A31; }

  @include max-w($laptop_sm) {
    padding: 15px 30px; } }

.Nav__List {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;

  @include max-w($laptop_sm) {
    flex-direction: column; } }

.Nav__Link {
  display: block;
  color: #EDEDED;

  &--Active {
    color: #fff;
    font-weight: 600; } }

.Nav__Item {
  padding: 8px;
  border-right: 1px solid #EDEDED;

  span.active {
    text-decoration: underline; }

  a, span {
    cursor: pointer; }

  &:first-child {
    padding-left: 0; }

  &:last-child {
    border-right: none;
    padding-right: 0;

    @include max-w($laptop_sm) {
      border-bottom: none; } }

  @include max-w($laptop_sm) {
    padding: 15px 0;
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #EDEDED; } }
