.footer {
  padding: 41px 0 50px !important;
  background-color: none !important;
  color: var(--white);
  font-family: "MacPawFixelDisplay-Light";
  font-size: 18px;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

.footer-light {
  background-color: var(--white-3) !important;
}

.footer__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.footer__logo {
  display: block !important;
}
@media (max-width: 768px) {
  .footer__top {
    margin-bottom: 44px;
  }
}

.footer__social {
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding-bottom: 9px;
  gap: 0 !important;
}

.footer__social a {
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 25px;
  background-color: white;
}
.footer__social a img {
  width: 16px;
  height: 16px;
}
.footer__social a:last-child {
  margin: 0;
}

.footer__info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 77px;
}

@media (max-width: 1200px) {
  .footer__info {
    flex-wrap: wrap;
    margin-bottom: 27px;
  }
}

@media (max-width: 992px) {
  .footer__info {
    flex-direction: column;
  }
}

.footer__links {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .footer__links {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

@media (max-width: 992px) {
  .footer__links {
    flex-direction: column;
    padding-bottom: 3px;
  }
}

.footer__links-cell {
  padding-right: 60px;
}

.footer__links-cell:nth-child(1) {
  padding-right: 42px;
}

.footer__links-cell:nth-child(2) {
  padding-right: 57px;
}

.footer__links-cell:nth-child(3) {
  padding-right: 43px;
}

@media (max-width: 1200px) {
  .footer__links-cell {
    margin-bottom: 28px;
  }
}

@media (max-width: 992px) {
  .footer__links-cell:nth-child(1),
  .footer__links-cell:nth-child(2),
  .footer__links-cell:nth-child(3),
  .footer__links-cell:nth-child(4) {
    padding-right: 0;
  }
}

.footer__link-title {
  font-family: "MacPawFixelDisplay-Bold";
  font-size: 22px;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 13px;
  margin: 0 0 30px;
  margin-right: -43px;
}

.footer__links-cell:nth-child(1) .footer__link-title {
  margin-right: -42px;
}

.footer__links-cell:nth-child(2) .footer__link-title {
  margin-right: -57px;
}

@media (max-width: 992px) {
  .footer__link-title {
    display: inline-flex;
    padding-bottom: 0;
    padding-right: 60px;
    margin: 0 0 23px;
  }
}

.footer-light__link-title {
  border-bottom: 1px solid rgba(98, 95, 95, 0.2);
  color: var(--dark);
}

.footer__list-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer__list-links li {
  margin-bottom: 20px;
}

.footer-dark {
  background-color: var(--dark) !important;
}
.footer-dark .footer__link {
  color: var(--white);
  margin-bottom: 0;
  font-weight: 600;
}

.footer-dark .footer__link:hover {
  text-decoration: unset;
  color: unset;
}

.footer-light .footer__link {
  font-family: "MacPawFixelDisplay-Medium";
  color: var(--dark);
  margin-bottom: 0;
}

.footer__auth {
  margin-bottom: 75px;
  text-align: right;
  padding-top: 7px;
}

@media (max-width: 992px) {
  .footer-light__auth,
  .footer__auth {
    display: none;
  }
}

.footer__auth span {
  color: var(--secondary-purple);
}

.footer__auth-link:first-child {
  color: var(--dark);
}

.footer__auth-link:first-child {
  color: var(--white-2);
}

.footer__auth-link:last-child {
  color: var(--secondary-purple);
  font-family: "MacPawFixelDisplay-Medium";
}

.footer-light__auth-link:first-child {
  color: var(--dark);
  font-family: "MacPawFixelDisplay-Medium";
}

@media (max-width: 992px) {
  .footer__phones {
    padding-top: 2px;
  }
}

@media (max-width: 992px) {
  .footer-dark__phones {
    padding-top: 6px;
  }
}

.footer__phone {
  color: var(--white);
  margin-bottom: 23px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 992px) {
  .footer__phone {
    justify-content: flex-start;
    margin-bottom: 21px;
  }
}

.footer__phone a {
  color: var(--white);
}

.footer__phone-light a {
  font-family: "MacPawFixelDisplay-Medium";
  color: var(--dark);
}

.footer__phone img {
  margin-right: 12px;
}

@media (max-width: 768px) {
  .footer__phone img {
    margin-right: 5px;
  }
}

.footer__copy {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 998px) {
  .footer__copy {
    flex-direction: column-reverse;
    gap: 35px;
    align-items: normal;
  }
}

.footer__copy-left {
  padding: 13px 0;
}

.footer__copy-right {
  font-size: 16px;
  text-align: right;
  line-height: normal;
  color: white;
}

.footer-light__copy-right {
  color: var(--dark) !important;
}

.footer-light__copy-left {
  padding: 13px 0;
}

@media (max-width: 998px) {
  .footer__copy-right {
    text-align: left;
  }
}

.footer__copy-left img {
  margin-bottom: 23px;
}

@media (max-width: 768px) {
  .footer__copy-left {
    text-align: left;
  }
  .footer__copy-left img {
    margin-bottom: 13px;
  }
}

.footer__copy-left p {
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  margin: 0;
}
.footer-light__copy-left p {
  color: var(--dark);
  opacity: 0.5;
}
