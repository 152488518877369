@import "../../common/variables";
@import "../../common/mixins";

.SelectSingle {
  width: 100%; }

.SelectSingle__Label {
  display: inline-block;
  font-size: 14px;
  color: $color-seventh;
  font-weight: 400;
  margin-bottom: 10px; }
.SelectSingle--disabled {
  background-color: #e9ecef;
  & .SelectSingle__single-value--is-disabled {
    color: black !important; } }
.SelectSingle__control {
  background-color: transparent !important;
  border: 0.5px solid rgba($color-background, .4) !important;
  outline: none !important;
  height: 46px; }

.SelectSingle__placeholder, .SelectSingle__single-value {
  font-size: 18px;
  font-weight: 300 !important; }

.SelectSingle__indicator-separator {
  display: none; }

.SelectSingle__indicator {
  padding-right: 13px !important;
  padding-left: 0 !important; }

.SelectSingle__option {
  .Form-Select--White {
    color: #000; } }

.SelectSingle__option--is-selected {
  color: #fff; }

.SelectSingle--Light {
  .SelectSingle__menu {
    background-color: $color-background !important; }
  .SelectSingle__indicator {
    & svg path {
      stroke: white; } }
  .SelectSingle__option--is-selected {
    background-color: $color-dark-input-background; }

  .SelectSingle__option--is-focused {
    background-color: $color-dark-input-background; }
  .SelectSingle__single-value {
    color: white !important;
    font-weight: 300 !important; }
  .SelectSingle__placeholder {
    color: white; } }
