@import "../../../../common/variables";
@import "../../../../common/mixins";

.TablePagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;

  @include max-w($mobile_lg) {
    flex-direction: column; } }

.TablePagination__Count {
  color: $color-seventh;
  font-size: 14px;
  line-height: 17px;

  @include max-w($mobile_lg) {
    margin-bottom: 30px; } }

.TablePagination__Item {
  background: transparent;
  border: 0.4px solid #000;
  border-radius: 5px;
  padding: 7px 11px 7px;
  color: $color-background;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  min-width: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 19px; }

  &:nth-child(4) {
    padding: 7px 10px 7px; }

  &:last-child {
    padding: 7px 14px 7px 15px; }

  &--Active {
    background-color: $color-primary;
    border-color: $color-primary;
    color: #fff;
    cursor: default; }

  svg {
    margin-left: 10px; } }

.TablePagination__Controls {
  display: flex; }

