@import "../../../../common/variables";
@import "../../../../common/mixins";

.custom__tooltip {
    & img {
        width: 23px;
        height: 23px;
        margin-bottom: 0;
        position: relative;
        z-index: 10; }
    &-wrapper {
        position: absolute;
        z-index: 100;
        left: 0;
        right: 0;
        margin-top: -6px;
        display: none;
        & svg {
            z-index: -1; } }
    &-content {
        border: 1px solid var(--secondary-purple-3);
        background: var(--white-4);
        margin-top: -2px;
        border-top: none;
        padding: 16px;
        padding-top: 1px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        color: var(--primary-purple); }
    &-header {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        & >div:first-child {
            border: 1px solid var(--secondary-purple-3);
            border-right: none;
            border-bottom: none;
            height: 17px;
            flex: 1;
            margin-right: -2px;
            background: var(--white-4);
            border-top-left-radius: 10px; } }
    &:hover {
        & .custom__tooltip-wrapper {
            display: block; } } }
