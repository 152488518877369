@import "../../common/variables";
@import "../../common/mixins";

.section {
  margin-bottom: 151px;

  @include max-w($tablet) {
    margin-bottom: 86px; }

  &--hero {
    margin-bottom: 101px; }

  &--exchange {
    margin-bottom: 0;
    padding-bottom: 63px; }

  &--contacts {
    margin-bottom: 100px;

    @include max-w($mobile_md) {
      margin-bottom: 150px; } }

  &--steps {
    margin-bottom: 120px; }

  &--features {
    margin-bottom: 98px; }

  &--assets {
    @include max-w($tablet) {
      margin-bottom: 131px; } }

  &--about-assets {
    @include max-w($tablet) {
      margin-bottom: 100px; } }

  &--mb-md {
    margin-bottom: 100px;

    @include max-w($mobile_lg) {
      margin-bottom: 70px; } }

  &--mb-0 {
    margin-bottom: 0; } }

