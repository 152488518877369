@import "../../common/variables";
@import "../../common/mixins";

.custom_stepper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .stepper-item {
        text-align: center;
        max-width: 160px;
        min-width: 160px;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:not(:last-of-type) {
            position: relative;
            margin-right: 88px;
            @include max-w($mobile_md) {
                margin-right: 40px; }
            & span:first-of-type::after {
                position: absolute;
                content: "";
                width: calc(100% + 44px);
                height: 1px;
                border-bottom: 2px solid #5CA1FF;
                left: calc(50% + 24px);
                @include max-w($mobile_md) {
                    width: calc(100% - 8px); } } }
        & span {
            display: inline-flex;
            &:first-of-type {
                margin-bottom: 10px;
                width: 24px;
                height: 24px;
                border-radius: 16px;
                align-items: center;
                justify-content: center;
                box-shadow: inset 0 0 0 2px #5CA1FF;
                color: #5CA1FF; }
            &:last-of-type {
                @include max-w($mobile_md) {
                    display: block;
                    max-width: 140px; } } }
        &.active {
            span:last-of-type {
                color: #5CA1FF; }
            span:first-of-type {
                background: #5CA1FF;
                color: white;
                box-shadow: none; } } } }
