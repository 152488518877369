/*--------------------------------------------------------------
# fonts
--------------------------------------------------------------*/
@font-face {
  font-family: "MacPawFixelDisplay-Black";
  src: url("/Fonts/MacPawFixelDisplay/MacPawFixelDisplay-Black.woff") format("woff"),
    url("/Fonts/MacPawFixelDisplay/MacPawFixelDisplay-Black.woff2") format("woff2");
}

@font-face {
  font-family: "MacPawFixelDisplay-ExtraBold";
  src: url("/Fonts/MacPawFixelDisplay/MacPawFixelDisplay-ExtraBold.woff") format("woff"),
    url("/Fonts/MacPawFixelDisplay/MacPawFixelDisplay-ExtraBold.woff2") format("woff2");
}

@font-face {
  font-family: "MacPawFixelDisplay-Bold";
  src: url("/Fonts/MacPawFixelDisplay/MacPawFixelDisplay-Bold.woff") format("woff"),
    url("/Fonts/MacPawFixelDisplay/MacPawFixelDisplay-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "MacPawFixelDisplay-Medium";
  src: url(/Fonts/MacPawFixelDisplay/MacPawFixelDisplay-Medium.woff) format("woff"),
    url(/Fonts/MacPawFixelDisplay/MacPawFixelDisplay-Medium.woff2) format("woff2");
}

@font-face {
  font-family: "MacPawFixelDisplay-SemiBold";
  src: url("/Fonts/MacPawFixelDisplay/MacPawFixelDisplay-SemiBold.woff") format("woff"),
    url("/Fonts/MacPawFixelDisplay/MacPawFixelDisplay-SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: "MacPawFixelDisplay-Regular";
  src: url("/Fonts/MacPawFixelDisplay/MacPawFixelDisplay-Regular.woff") format("woff"),
    url("/Fonts/MacPawFixelDisplay/MacPawFixelDisplay-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "MacPawFixelDisplay-Light";
  src: url("/Fonts/MacPawFixelDisplay/MacPawFixelDisplay-Light.woff") format("woff"),
    url("/Fonts/MacPawFixelDisplay/MacPawFixelDisplay-Light.woff2") format("woff2");
}

@font-face {
  font-family: "MacPawFixelDisplay-ExtraLight";
  src: url("/Fonts/MacPawFixelDisplay/MacPawFixelDisplay-ExtraLight.woff") format("woff"),
    url("/Fonts/MacPawFixelDisplay/MacPawFixelDisplay-ExtraLight.woff2") format("woff2");
}

@font-face {
  font-family: "MacPawFixelDisplay-Thin";
  src: url("/Fonts/MacPawFixelDisplay/MacPawFixelDisplay-Thin.woff") format("woff"),
    url("/Fonts/MacPawFixelDisplay/MacPawFixelDisplay-Thin.woff2") format("woff2");
}
/*--------------------------------------------------------------
# end fonts
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# variables
--------------------------------------------------------------*/
:root {
  --dark: #000000;
  --dark-2: #1f2525;
  --dark-3: #121933;
  --dark-4: #0d1118;

  --white: #fff;
  --white-2: #ddd9f8;
  --white-3: #f5f5f5;
  --white-4: #f6f2fd;

  --primary-purple: #5405d6;
  --primary-purple-2: #7c2dfd;

  --secondary-purple: #694d97;
  --secondary-purple-2: #906fc8;
  --secondary-purple-3: #decefb;

  --grey: #bcc2c7;
  --grey-2: #d6dcdc;
  --grey-3: #abaeb2;

  --red: #ff5a5a;
  --green: #3c9f00;
  --error: #f95959;

  /* accent-color: var(--primary-purple); */

  --b-select-border-light: rgba(0, 0, 0, 0.25);
  --b-select-bg-light: #f5f5f5;
  --b-select-hover-light: #dcdcdc;
  --b-select-list-bg-light: #f0f0f0;
  --b-select-list-border-light: #c1c2f3;

  --b-select-border-dark: #8b8997;
  --b-select-list-bg-dark: #25203a;
  --b-select-hover-dark: #383639;

  --wizarbit-tooltip-background: #f6f2fd;
}
/*--------------------------------------------------------------
# end variables
--------------------------------------------------------------*/

.custom_toast-wrapper {
  padding: 0 !important;
}
.Toastify__toast {
  border-radius: 31px !important;
  padding: 16px !important;
  font-size: 18px;
  font-family: "MacPawFixelDisplay-Regular" !important;
}
.Toastify__toast-body {
  padding: 0 !important;
}
.Toastify__close-button {
  min-width: 38px;
  width: 38px;
  height: 38px;
  background: url(/img/toast-close.svg) no-repeat !important;
  background-position: center;
}
.Toastify__close-button svg {
  display: none;
}
