@import "../../common/variables";
@import "../../common/mixins";

.AuthPage {
  background-color: $color-background-second;
  color: $color-text-second;
  padding-top: 46px; }

.AuthPage__Title {
  margin-bottom: 25px; }

.AuthPage__Title-Main {
  margin-bottom: 60px; }

.AuthPage__SubTitle {
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
  color: $color-seventh;
  margin-bottom: 40px;
  text-align: center;

  a {
    color: $color-second; }

  @include max-w($mobile_md) {
    margin-bottom: 30px; } }

.AuthPage__Box {
  box-shadow: 0px 4px 117px rgba(0, 0, 0, 0.03);
  border-radius: 41px;
  background: #fff;
  padding: 40px;

  @include max-w($mobile_md) {
    padding: 30px 40px;
    display: flex;
    flex-direction: column; } }

.AuthPage__Row {
  display: flex;
  margin: 0 -15px;

  &:last-child {
    margin-bottom: 0; }

  &--mb-15 {
    margin-bottom: 15px;

    @include max-w($mobile_md) {
      margin-bottom: 0; } }

  &--mb-40 {
    margin-bottom: 40px;

    @include max-w($mobile_md) {
      margin-bottom: 0; } }

  @include max-w($mobile_md) {
    flex-direction: column; } }

.AuthPage__Col {
  padding: 0 15px;
  width: 100%;

  @include max-w($mobile_md) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 30px;

    .AuthPage__Row {
      .AuthPage__Col {
        &:last-child {
          padding-bottom: 0; } } } } }

.AuthPage__Link {
  font-size: 12px;
  margin-bottom: 25px;
  display: inline-block; }
