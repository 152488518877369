@import "../../../../common/variables";
@import "../../../../common/mixins";


.card_details_header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & span {
    white-space: nowrap; } }
.card_status_label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  padding: 4px 8px;
  border-radius: 8px;
  min-width: 80px;
  text-align: center;
  margin-left: 12px;
  &.active {
    background-color: #36c640;
    color: white; }
  &.locked {
    background-color: var(--bs-red);
    color: white; }
  &.not_active {
    background-color: var(--bs-orange);
    color: white; } }

.card_details_balance {
  gap: 8px; }
.card_balance {
  display: flex;
  align-items: center; }

