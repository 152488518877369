.custom__checkbox {
  font-size: 22px;
  font-family: "MacPawFixelDisplay-Regular";
  line-height: normal;
  position: relative;
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 768px) {
  .custom__checkbox {
    font-size: 18px;
  }
}
.check__box {
  width: 22px;
  height: 22px;
  min-width: 22px;
  margin-right: 14px;
  border-radius: 50%;
  border: 1px solid var(--primary-purple);
  background-color: transparent;
  margin-top: 4px;
}

.check__input {
  position: absolute;
  appearance: none;
}

.check__input:checked + .check__box {
  background-image: url(/img/checkbox.svg);
  border: none;
}

.check__helper {
  color: var(--error);
  font-size: 16px;
  opacity: 0.8;
  font-family: "MacPawFixelDisplay-Light";
}
