@import "../../common/variables";
@import "../../common/mixins";

.Logo {
  font-family: $font-family-second;
  font-weight: bold;
  font-size: 24px;

  span {
    &:first-child {
      color: $color-text-base; }

    &:last-child {
      color: $color-second; } }

  &--Invert {
    span {
      &:first-child {
        color: $color-background; }

      &:last-child {
        color: $color-second; } } } }


