@import "../../../../common/variables";
@import "../../../../common/mixins";

.exchange__block {
    padding-top: 316px;
    max-width: 500px;
    margin: 0 auto;
    font-family: "MacPawFixelDisplay-Light";
    padding-bottom: 118px;
    @include max-w(992) {
        padding-top: 196px; }

    &-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        padding: 0 5px;
        white-space: nowrap;
        @include max-w(768) {
            flex-direction: column;
            text-align: center; } }
    &-info {
        font-size: 18px;
        text-align: center;
        padding-bottom: 36px;
        & span {
            font-family: "MacPawFixelDisplay-ExtraBold";
            margin-block: 10px; } } }
.exchange__fields {
    width: 430px;
    margin: 0 auto 40px;
    @include max-w(768) {
        margin-bottom: 40px;
        max-width: 343px;
        width: 100%; }
    & >*:first-of-type {
        margin-bottom: 40px; } }
