.help {
  padding: 55px 0 55px;
  font-family: "MacPawFixelDisplay-Light";
}

@media (max-width: 768px) {
  .help {
    padding: 55px 0 60px;
  }
}

.help__h2 {
  margin: 0 0 38px !important;
}

@media (max-width: 768px) {
  .help__h2 {
    font-size: 40px !important;
    text-align: center;
    margin: 0 0 28px;
  }
}

.help__row {
  display: grid;
  grid-template-columns: 370px auto;
  grid-template-rows: 1fr 1fr;
  grid-gap: 19px 40px;
}

@media (max-width: 768px) {
  .help__row {
    grid-template-columns: auto;
    grid-template-rows: 1fr;
    grid-gap: 18px;
  }
}

.help__cell {
  padding: 33px 25px 25px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.help__cell:first-child {
  grid-column: 1 / 2;
  grid-row: 1 / -1;
  background: var(--grey-2) url(/img/help-1.png) center center no-repeat;
  justify-content: space-between;
  padding: 35px 25px 35px 32px;
}

@media (max-width: 768px) {
  .help__cell:first-child {
    grid-column: 1 / 1;
    grid-row: 1;
    padding: 32px 25px 13px 27px;
  }
}

.help__cell:nth-child(2) {
  grid-column: 2 / 3;
  grid-row: 1;
  background: var(--dark-2) url(/img/help-sun.svg) center center no-repeat;
}

@media (max-width: 768px) {
  .help__cell:nth-child(2) {
    grid-column: 1 / 1;
    grid-row: 2;
    padding: 30px 25px 14px 25px;
    background: var(--dark-2) url(/img/help-sun.svg) 198px 18% no-repeat;
  }
}

.help__cell:last-child {
  grid-column: 2 / 3;
  grid-row: 2;
  background: var(--primary-purple) url(/img/help-3.png) 90% center no-repeat;
}

@media (max-width: 768px) {
  .help__cell:last-child {
    grid-column: 1 / 1;
    grid-row: 3;
    background: var(--primary-purple) url(/img/help-3.png) 270px -20% no-repeat;
    padding: 30px 25px 13px 25px;
  }
}

.help__title {
  font-size: 40px;
  line-height: normal;
  text-transform: uppercase;
  font-family: "MacPawFixelDisplay-Bold";
  color: var(--white);
  margin: 0 0 33px;
}

@media (max-width: 768px) {
  .help__title {
    font-size: 20px;
    margin: 0 0 11px;
  }
}

.help__cell:first-child .help__title {
  color: var(--dark);
}

@media (max-width: 768px) {
  .help__cell:nth-child(2) .help__title {
    margin: 0 0 15px;
  }

  .help__cell:nth-child(3) .help__title {
    margin: 0 0 16px;
  }
}

.help__describe {
  color: var(--white);
  font-size: 22px;
  line-height: normal;
  margin: 0 0 43px;
}

@media (max-width: 768px) {
  .help__describe {
    font-size: 18px;
    margin: 0 0 63px;
  }
}

.help__cell:first-child .help__describe {
  color: var(--dark);
  padding-top: 5px;
  margin-left: -7px;
}

@media (max-width: 768px) {
  .help__cell:first-child .help__describe {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .help__cell:nth-child(2) .help__describe,
  .help__cell:nth-child(3) .help__describe {
    margin: 0 0 12px;
  }
}

.help__cell a {
  margin-left: auto;
  color: var(--white);
}
.help__cell a:hover line,
.help__cell a:hover path {
  stroke: var(--primary-purple-2);
}
.help__cell:not(:first-of-type) line,
.help__cell:not(:first-of-type) path {
  stroke: white;
}

@media (max-width: 768px) {
  .help__cell a {
    margin-left: 0;
    margin-right: auto;
  }
}

.help__cell:first-child a {
  color: var(--dark);
}

.help__cell:first-child a path,
.help__cell:first-child a line {
  stroke: var(--dark);
}
