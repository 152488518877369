@import "../../common/variables";
@import "../../common/mixins";

.Social {
  width: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 41px;
  background: $color-sixth;
  border-radius: 5px;
  cursor: pointer;
  color: #5d617a;

  &:hover {
    color: $color-primary; }

  &--Invert {
    color: #fff; } }

.Social__Icon {
  width: 25px;
  height: 25px; }

