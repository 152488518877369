@import "../../common/variables";
@import "../../common/mixins";

.Form-Select {
  position: relative;
  z-index: 1; }

.Form-Select-Simple__control {
  background-color: transparent !important;
  border: none !important;
  outline: none !important; }

.Form-Select-Simple__placeholder, .Form-Select-Simple__single-value {
  font-size: 24px;
  line-height: 17px;
  font-weight: 400;

  @include max-w($mobile_md) {
    font-size: 22px; }

  @include max-w($mobile_xsm) {
    font-size: 18px; }

  .Form-Select--White & {
    color: #fff; }

  .Form-Select--Blue & {
    color: $color-primary; }

  .Form-Select--Orange & {
    color: $color-fifth; }

  .Form-InputBox--Dark & {
    font-size: 18px; }

  .Form-Select--Small & {
    font-size: 16px;
    line-height: 17px; } }

.Form-Select-Simple__indicator-separator {
  display: none; }

.Form-Select-Simple__indicator {
  padding-right: 0 !important;
  padding-left: 0 !important;
  opacity: 1 !important;

  svg {
    .Form-Select--White & {
      fill: #fff; }

    .Form-Select--Dark & {
      fill: #000; }

    .Form-Select--Blue & {
      fill: $color-primary; }

    .Form-Select--Orange & {
      fill: $color-fifth; } } }

.Form-Select-Simple__option {
  font-size: 14px !important;
  line-height: normal;
  .Form-Select--White & {
    color: #000; }

  .Form-Select--Blue & {
    color: $color-primary; }

  .Form-Select--Orange & {
    color: $color-fifth; } }

.Form-Select-Simple__option--is-selected {
  .Form-Select--Blue &, .Form-Select--Orange & {
    color: #fff; } }
