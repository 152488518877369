@import "../../../../common/variables";
@import "../../../../common/mixins";

.TransactionsFilter {
  background: #fff;
  border-radius: 21px;
  padding: 13px 20px 14px;
  margin-bottom: 40px;

  @include max-w($tablet) {
    padding: 24px 20px; } }

.TransactionFilter__Head {
  align-items: center;
  justify-content: space-between;
  display: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;

  svg {
    transition: transform .5s; }

  @include max-w($tablet) {
    display: flex; }

  .TransactionsFilter--Open & {
    svg {
      transform: rotate(180deg); } } }

.TransactionsFilter__Inner {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include max-w($tablet) {
    display: none;
    margin-top: 15px;
    flex-direction: column; }

  .TransactionsFilter--Open & {
    display: block; } }

.TransactionsFilter__Items {
  display: flex;
  align-items: center;
  max-width: 850px;
  flex-grow: 1;
  flex-wrap: wrap;

  @include max-w($tablet) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 25px; } }

.TransactionsFilter__Select {
  display: flex;
  align-items: center;
  margin-right: 64px;


  .Form-Select-Simple__placeholder, .Form-Select-Simple__single-value {
    font-weight: 500; }
  @include max-w($tablet) {
    margin-bottom: 5px; } }

.TransactionsFilter__Label {
  font-size: 14px;
  line-height: 17px;
  color: $color-seventh;
  margin-right: 9px; }

.TransactionsFilter__Reset {
  padding: 13px;
  min-width: auto;
  margin-left: 15px;

  .Button__Icon {
    margin: 2px 0 -2px;
    width: 24px; } }
